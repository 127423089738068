.CheckupDashBoard {
  width: 100%;

  margin: 0 auto;
}

.innerCheckupDashBoard {
  width: 100%;

  padding: 0em 2em;

  margin: 0 auto;
}

.checkupWrapList {
  width: 100%;

  display: flex;
  flex-direction: row;

  justify-content: space-around;
  align-items: flex-start;

  margin-bottom: 1em;

  border-bottom: 1px solid #9e9e9e;

  //   background-color: red;

  padding: 3em 0em;
}

.clientCheckupTitleAndParagraph {
  //   background-color: aquamarine;

  width: 30%;

  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;
}

.clientDashBoardTitleHeadText {
  width: 65%;
  //   background-color: red;

  padding: 0.5em 0em;

  border-bottom: 2.5px solid #0f2255;
}

.clientDashBoardTItleHeadP {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.8rem, 1.2vw, 1.2rem);
  font-weight: 600;

  color: #0f2255;
}

.clientDashBoardParagraph {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.7rem, 1.05vw, 1.05rem);
  //   font-weight: 600;

  margin-top: 1em;
}

.CheckupListWrap {
  width: 40%;

  //   background-color: yellowgreen;

  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;
}

.checkupList {
  width: 100%;

  margin: 0 auto;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  justify-content: space-evenly;
  align-items: center;

  cursor: pointer;
}

.checkupList > * {
  flex-grow: 1;
  flex-basis: 50%;
}

.checkListsDbrd:hover {
  background-color: #d3d3d36c;
}

.checkListsDbrd {
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  transition: 0.4s;

  gap: 1em;

  margin-bottom: 0.8em;

  padding: 0.5em;
  border-radius: 10px;
}

.listImage {
  width: 37px;
  aspect-ratio: 1/1;

  border-radius: 50px;

  background: rgba(27, 32, 52, 0.38);

  padding: 0.5em;
}

.clientCheckupListTitle {
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;
}

.listTitleHead {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.7rem, 1.05vw, 1.05rem);
  font-weight: 600;
}

.listTitleDesc {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.7rem, 1.05vw, 1.05rem);
  // font-weight: 600;
}

@media (max-width: 1165px) {
  .innerCheckupDashBoard {
    width: 100%;

    padding: 0em 0em;

    margin: 0 auto;
  }

  /*  .checkupWrapList {
    width: 100%;

    display: flex;
    flex-direction: row;

    justify-content: space-around;
    align-items: flex-start;

    margin-bottom: 1em;

    border-bottom: 1px solid #9e9e9e;

    //   background-color: red;

    padding: 3em 0em;
  } */

  .CheckupListWrap {
    width: 50%;

    //   background-color: yellowgreen;

    display: flex;
    flex-direction: row;

    justify-content: flex-start;
    align-items: center;

    // margin-inline-start: auto;
    // align-self: flex-start;
  }
}

@media (max-width: 1165px) {
  .CheckupListWrap {
    width: 55%;

    //   background-color: yellowgreen;

    display: flex;
    flex-direction: row;

    justify-content: flex-start;
    align-items: center;

    // margin-inline-start: auto;
    // align-self: flex-start;
  }
}

@media (max-width: 800px) {
  .checkupList {
    flex-direction: column;
  }
}

@media (max-width: 800px) {
  .checkupWrapList {
    width: 100%;

    display: flex;
    flex-direction: column;

    justify-content: space-around;
    align-items: flex-start;

    margin-bottom: 1em;

    border-bottom: 1px solid #9e9e9e;

    //   background-color: red;

    padding: 3em 0em;
  }

  .clientCheckupTitleAndParagraph {
    //   background-color: aquamarine;

    width: 80%;

    margin: 0 auto;

    margin-bottom: 2em;
  }

  .CheckupListWrap {
    width: 80%;

    //   background-color: yellowgreen;
  }
}

@media (max-width: 1170px) {
}
