.DashboardHeader {
  width: 100%;

  margin: 0 auto;

  background-color: #f4f4f4;
}

.dashboardInnerHeader {
  width: 100%;

  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  margin: 0 auto;

  padding: 0em 2em;
}

.logoAndAccName {
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  gap: 0.4em;
}

.weCareLogo {
  width: 5vw;
}

.avatarAccName {
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 1em;

  padding-bottom: 0.5em;
}

.accNameOutline {
  width: 50px;

  aspect-ratio: 1/1;

  border-radius: 50px;

  border: 2px solid blue;

  display: grid;
  place-content: center;
}

.accAvatar {
  width: 45px;
  aspect-ratio: 1/1;

  border-radius: 50px;

  object-fit: cover;
}

.accName {
  font-family: "poppins", sans-serif;
  font-size: clamp(0.6rem, 0.85vw, 0.85rem);
  font-weight: 600;
}

.syncTime {
  font-family: "poppins", sans-serif;
  font-size: clamp(0.55rem, 0.8vw, 0.8rem);
  //   font-weight: 600;

  color: rgb(49, 49, 49);
}

.navAndTools {
  //   width: 100%;
  height: 100px;
  display: flex;
  flex-direction: column;

  justify-content: flex-end;
  align-items: flex-end;

  //   background-color: red;

  gap: 0.7em;

  position: relative;
}

.tools {
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 2em;

  position: absolute;
  top: 1em;
}

.toolImg {
  width: 18px;

  cursor: pointer;
}

.toolImg:hover {
  //   width: 20px;

  background-color: grey;

  padding: 0.2em 0.2em;

  border-radius: 50px;

  transition: 0.4s;
}

.dashboardNav {
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  // background-color: aqua;
  margin-bottom: 0.6em;
}

.dashboardListWrap {
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  list-style: none;

  gap: 3em;

  // margin-top: -2.8em;
  margin-right: 1em;
}

.dashboardNavLink .dshBrdLnk {
  font-family: "poppins", sans-serif;
  font-size: clamp(0.8rem, 0.9vw, 0.9rem);

  text-decoration: none;
  color: #000;

  //   border-bottom: 3.5px solid orange;
  padding: 0em 0.5em 0.7em;
}

.dashboardNavLink .dshBrdLnk:focus {
  font-weight: 500;
  border-bottom: 3.5px solid orange;

  animation: navBorderStyle 0.5s linear 1;
}

@keyframes navBorderStyle {
  0% {
    width: 0%;
  }

  100% {
    width: 100%;
  }
}

.dashboardMenu {
  width: 30px;
  aspect-ratio: 1/1;

  //   background-color: gray;

  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-end;

  cursor: pointer;

  position: relative;
}

.menuLine {
  width: 70%;
  height: 2px;

  background-color: #000;

  margin-top: 0.15em;
}

.menuLine:nth-child(2) {
  width: 45%;
}

.menuList {
  width: 200px;
  height: 250px;
  background-color: rgb(223, 223, 223);

  // position: relative;

  position: fixed;
  top: 5em;
  right: 1em;

  border-radius: 10px;
  box-shadow: 0px 0px 10px -5px;

  animation: menuReveal 0.1s linear;

  z-index: 999999;
}

@keyframes menuReveal {
  0% {
    height: 0px;
  }
  100% {
    height: 250px;
  }
}

.menuListLinks {
  list-style-type: none;

  padding: 2em 0em;

  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  gap: 0em;
}

.menuListLink {
  width: 100%;
  font-family: "poppins", sans-serif;
  font-size: clamp(0.85rem, 1vw, 1rem);
}

.dashHeadMnLnk:hover,
.dashHeadMnLnk:focus {
  background-color: rgb(99, 99, 99);
  color: #fff;
}

.displaYNone {
  display: none;
}

.dashHeadMnLnk {
  width: 100%;

  display: block;

  text-decoration: none;
  color: black;

  padding: 0.5em 1em;

  margin-bottom: 0.2em;
}

.close .menuLine:nth-child(1) {
  width: 20px;
  position: absolute;
  transform-origin: 50% 50%;
  transform: translate(5%) rotate(-45deg);
  transition: 0.1s;
}

.close .menuLine:nth-child(2) {
  display: none;
}

.close .menuLine:nth-child(3) {
  width: 20px;
  position: absolute;
  transform-origin: 50% 50%;

  transform: translate(5%) rotate(45deg);
  transition: 0.1s;
}

@media (max-width: 880px) {
  .navAndTools {
    // width: 100%;
    height: 100px;

    display: flex;
    flex-direction: row;

    justify-content: flex-end;
    align-items: center;

    // background-color: red;

    gap: 1em;

    position: relative;
  }

  .displayNone {
    display: none;
  }

  .tools {
    display: flex;
    flex-direction: row;

    justify-content: center;
    align-items: center;

    gap: 2em;

    position: relative;
    top: 0em;

    margin-top: -0.5em;
  }

  .dashboardListWrap {
    width: 200px;

    // background-color: aqua;

    position: fixed;
    right: 0;
    top: 6.5em;

    flex-direction: column;

    justify-content: flex-start;
    align-items: flex-start;

    gap: 0.2em;
    z-index: 9999999;
  }

  .dashboardNavLink {
    width: 100%;
  }

  .dashboardNavLink .dshBrdLnk {
    width: 100%;

    font-family: "poppins", sans-serif;
    font-size: clamp(0.85rem, 1vw, 1rem);

    text-decoration: none;
    color: #000;

    //   border-bottom: 3.5px solid orange;
    padding: 0.5em 2em;

    display: block;
  }

  .dashboardNavLink .dshBrdLnk:focus {
    font-weight: 500;
    border-bottom: 0px solid orange;

    animation: none;

    background-color: rgb(99, 99, 99);
    color: #fff;
  }

  .dashboardNavLink .dshBrdLnk:hover {
    background-color: rgb(99, 99, 99);
    color: #fff;
  }

  /* .dashboardListWrap {
    display: flex;
    flex-direction: row;

    justify-content: space-between;
    align-items: center;

    list-style: none;

    gap: 3em;

    // margin-top: -2.8em;
    margin-right: 1em;
  } */

  .menuList {
    width: 200px;
    height: 350px;
    background-color: rgb(223, 223, 223);

    // position: relative;

    position: fixed;
    top: 5em;
    right: 1em;

    border-radius: 10px;
    box-shadow: 0px 0px 10px -5px;

    animation: menuReveal 0.2s linear;
  }

  .menuListLinks {
    margin-top: 6.5em;
  }

  .dashHeadMnLnk {
    padding: 0.5em 2em;
  }
}

@media (max-width: 570px) {
  .nameSyncTime {
    display: none;
  }

  .weCareLogo {
    width: 50px;
  }
}
