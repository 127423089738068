section {
  width: 100%;
  margin: 0 auto;
}

.innerFirstSection {
  width: 100%;

  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  background-color: rgb(255, 255, 255);

  padding: 2em;
  padding-top: 5em;
}

.SectionHeads {
  width: 100%;

  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;
}

.sectionHeadText {
  font-family: "poppins", sans-serif;
  font-size: clamp(1.1rem, 2.5vw, 2.5rem);
  font-weight: 700;

  //   line-height: 1.5rem;

  & .partner {
    display: block;
    font-size: clamp(1.3rem, 3vw, 3rem);

    & .homeCareColor {
      color: #dc9402;
    }
  }
}

.sectionHeadParagraph {
  width: 300px;
  min-width: 10px;
  font-family: "poppins", sans-serif;
  font-size: clamp(0.6rem, 1.3vw, 1.3rem);

  margin-top: 1em;
}

.bookFreeDemo {
  border: none;
  border-radius: 50px;

  padding: 0.7em 1.5em;

  font-family: "poppins", sans-serif;
  font-size: clamp(0.6rem, 1.3vw, 1.3rem);
  font-weight: 800;

  text-transform: uppercase;

  margin-top: 1em;

  background: linear-gradient(180deg, #ffc143 0%, #bf7300 100%);
  color: #000;
  cursor: pointer;

  transition: all 0.5s;
}

.bookFreeDemo:hover {
  background: linear-gradient(180deg, #bf7300 4.69%, #ffc143 96.87%);
  color: rgb(82, 82, 82);
}

.inAssociation {
  width: 100%;

  margin-top: 1em;

  & .inAssociate {
    font-family: "poppins", sans-serif;
    font-size: clamp(0.6rem, 1vw, 1rem);
    font-weight: 700;
  }

  & .associateGroup {
    background-color: #dcdcdc;
    width: 100%;

    display: flex;
    flex-direction: row;

    justify-content: space-around;
    align-items: center;

    padding: 0.3em;

    margin-top: 0.3em;

    & .assoImg {
      width: 40px;
    }
  }
}

.SectionPicture {
  width: 100%;
  height: 45vh;

  background-image: url(../assets/HeroPicture/Group28.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  margin-top: 2em;
}

.SectionLine {
  width: 100%;

  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  gap: 0.5em;

  margin: 1em 0em;

  & .line {
    width: 40%;
    height: 1px;

    background-color: grey;
  }

  & .circle {
    width: 20px;
    height: 20px;

    border-radius: 50px;
    border: 1px solid grey;

    // background-color: red;

    display: flex;
    flex-direction: row;

    justify-content: center;
    align-items: center;
  }

  & .arrowDown {
    width: 7px;
    height: 8px;
  }
}

@media (min-width: 570px) {
  .SectionHeads {
    align-items: center;
  }

  .bookFreeDemo {
    border: none;
    border-radius: 50px;

    padding: 0.7em 1.5em;

    font-family: "poppins", sans-serif;
    font-size: clamp(0.7rem, 1.5vw, 1.5rem);
    font-weight: 800;

    text-transform: uppercase;

    margin-top: 1em;

    background: linear-gradient(180deg, #ffc143 0%, #bf7300 100%);
    color: #000;
    cursor: pointer;

    transition: all 0.5s;
  }

  .innerFirstSection {
    width: 100%;

    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;

    background-color: rgb(255, 255, 255);

    padding: 2em;
    padding-top: 6em;
  }

  .sectionHeadText {
    font-family: "poppins", sans-serif;
    font-size: clamp(1.3rem, 3.5vw, 3.5rem);
    font-weight: 700;

    //   line-height: 1.5rem;
    text-align: center;

    & .partner {
      display: block;
      font-size: clamp(1.3rem, 3vw, 3rem);

      & .homeCareColor {
        color: #dc9402;
      }
    }
  }

  .sectionHeadParagraph {
    width: 350px;
    min-width: 10px;
    font-family: "poppins", sans-serif;
    font-size: clamp(0.75rem, 1.8vw, 1.8rem);

    margin-top: 1em;

    text-align: center;
  }

  .inAssociation {
    width: 70%;

    margin-top: 1em;

    & .inAssociate {
      font-family: "poppins", sans-serif;
      font-size: clamp(0.6rem, 1vw, 1rem);
      font-weight: 700;
    }

    & .associateGroup {
      background-color: #dcdcdc;
      width: 100%;

      display: flex;
      flex-direction: row;

      justify-content: space-around;
      align-items: center;

      padding: 0.3em;

      margin-top: 0.3em;

      & .assoImg {
        width: 40px;
      }
    }
  }
}

@media (min-width: 828px) {
  .innerFirstSection {
    width: 100%;

    display: flex;
    flex-direction: row;

    justify-content: center;
    align-items: center;

    background-color: rgb(255, 255, 255);

    padding: 2em;
    margin-top: 0em;

    padding-top: 7em;

    position: relative;
  }

  .SectionHeads {
    align-items: flex-start;
    margin-bottom: 2em;
  }

  .bookFreeDemo {
    border: none;
    border-radius: 50px;

    padding: 0.7em 1.5em;

    font-family: "poppins", sans-serif;
    font-size: clamp(0.6rem, 1.5vw, 1.5rem);
    font-weight: 800;

    text-transform: uppercase;

    margin-top: 1em;

    background: linear-gradient(180deg, #ffc143 0%, #bf7300 100%);
    color: #000;
    cursor: pointer;

    transition: all 0.5s;
  }

  .sectionHeadText {
    font-family: "poppins", sans-serif;
    font-size: clamp(1.1rem, 3vw, 3rem);
    font-weight: 700;

    //   line-height: 1.5rem;
    text-align: left;

    & .partner {
      display: block;
      font-size: clamp(1.2rem, 3vw, 3rem);

      & .homeCareColor {
        color: #dc9402;
      }
    }
  }

  .sectionHeadParagraph {
    width: 350px;
    min-width: 10px;
    font-family: "poppins", sans-serif;
    font-size: clamp(0.75rem, 1.8vw, 1.8rem);

    margin-top: 1em;

    text-align: left;
  }

  .inAssociation {
    width: 100%;

    margin-top: 1em;

    & .inAssociate {
      font-family: "poppins", sans-serif;
      font-size: clamp(0.6rem, 1vw, 1rem);
      font-weight: 700;
    }

    & .associateGroup {
      background-color: #dcdcdc;
      width: 100%;

      display: flex;
      flex-direction: row;

      justify-content: space-around;
      align-items: center;

      padding: 0.3em;

      margin-top: 0.3em;

      & .assoImg {
        width: 40px;
      }
    }
  }

  .SectionPicture {
    width: 100%;
    height: 45vh;

    background-image: url(../assets/HeroPicture/Group28.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    margin-top: 0em;
    margin-bottom: 2em;
  }

  .SectionLine {
    width: 90%;
    position: absolute;
    bottom: 0em;

    justify-content: space-between;
    align-items: center;

    margin: 0;
  }
}

@media (min-width: 971px) {
  .innerFirstSection {
    width: 100%;

    display: flex;
    flex-direction: row;

    justify-content: space-between;
    align-items: center;

    background-color: rgb(255, 255, 255);

    padding: 2em;
    margin-top: 0em;

    padding-top: 9em;

    position: relative;
  }

  .SectionHeads {
    align-items: flex-start;
    margin-bottom: 2em;
  }

  .bookFreeDemo {
    border: none;
    border-radius: 50px;

    padding: 0.7em 1.5em;

    font-family: "poppins", sans-serif;
    font-size: clamp(0.6rem, 1.3vw, 1.3rem);
    font-weight: 800;

    text-transform: uppercase;

    margin-top: 1em;

    background: linear-gradient(180deg, #ffc143 0%, #bf7300 100%);
    color: #000;
    cursor: pointer;

    transition: all 0.5s;
  }

  .sectionHeadText {
    font-family: "poppins", sans-serif;
    font-size: clamp(1rem, 2.3vw, 2.3rem);
    font-weight: 700;

    //   line-height: 1.5rem;
    text-align: left;

    & .partner {
      display: block;
      font-size: clamp(1.2rem, 2.8vw, 2.8rem);

      & .homeCareColor {
        color: #dc9402;
      }
    }
  }

  .sectionHeadParagraph {
    width: 350px;
    min-width: 10px;
    font-family: "poppins", sans-serif;
    font-size: clamp(0.75rem, 1.3vw, 1.3rem);

    margin-top: 1em;

    text-align: left;
  }

  .inAssociation {
    width: 70%;

    margin-top: 1em;

    & .inAssociate {
      font-family: "poppins", sans-serif;
      font-size: clamp(0.6rem, 1vw, 1rem);
      font-weight: 700;
    }

    & .associateGroup {
      background-color: #dcdcdc;
      width: 100%;

      display: flex;
      flex-direction: row;

      justify-content: space-around;
      align-items: center;

      padding: 0.3em;

      margin-top: 0.3em;

      & .assoImg {
        width: 40px;
      }
    }
  }

  .SectionPicture {
    width: 70%;
    height: 45vh;

    background-image: url(../assets/HeroPicture/Group28.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    margin-top: 0em;
    margin-bottom: 2em;

    // background-color: red;
  }

  .SectionLine {
    position: absolute;
    bottom: 0em;
  }
}

@media (min-width: 1100px) {
  .innerFirstSection {
    width: 100%;

    display: flex;
    flex-direction: row;

    justify-content: space-between;
    align-items: center;

    background-color: rgb(255, 255, 255);

    padding: 2em;
    margin-top: 0em;

    padding-top: 9em;

    position: relative;
  }

  .SectionHeads {
    align-items: flex-start;
    margin-bottom: 2em;
  }

  .bookFreeDemo {
    border: none;
    border-radius: 10px;

    padding: 1.2em 1.5em;

    font-family: "poppins", sans-serif;
    font-size: clamp(0.6rem, 1.3vw, 1.3rem);
    font-weight: 800;

    text-transform: uppercase;

    margin-top: 1em;

    background: linear-gradient(180deg, #ffc143 0%, #bf7300 100%);
    color: #000;
    cursor: pointer;

    transition: all 0.5s;
  }

  .sectionHeadText {
    font-family: "poppins", sans-serif;
    font-size: clamp(1rem, 2.7vw, 2.7rem);
    font-weight: 700;

    //   line-height: 1.5rem;
    text-align: left;

    & .partner {
      display: block;
      font-size: clamp(1.2rem, 3.2vw, 3.2rem);

      & .homeCareColor {
        color: #dc9402;
      }
    }
  }

  .sectionHeadParagraph {
    width: 85%;
    min-width: 10px;
    font-family: "poppins", sans-serif;
    font-size: clamp(0.8rem, 1.3vw, 1.3rem);

    margin-top: 1em;

    text-align: left;

    // background-color: red;
  }

  .inAssociation {
    width: 85%;

    margin-top: 1.7em;

    & .inAssociate {
      font-family: "poppins", sans-serif;
      font-size: clamp(0.6rem, 1vw, 1rem);
      font-weight: 700;
    }

    & .associateGroup {
      background-color: #dcdcdc;
      width: 100%;

      display: flex;
      flex-direction: row;

      justify-content: space-around;
      align-items: center;

      padding: 0.3em;

      margin-top: 0.3em;

      & .assoImg {
        width: 60px;
      }
    }
  }

  .SectionPicture {
    width: 85%;
    height: 55vh;

    background-image: url(../assets/HeroPicture/Group28.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    margin-top: 0em;
    margin-bottom: 2em;

    // background-color: red;
  }

  .SectionLine {
    position: absolute;
    bottom: 0em;
  }
}

@media (min-width: 1150px) {
  .innerFirstSection {
    width: 100%;

    display: flex;
    flex-direction: row;

    justify-content: space-between;
    align-items: center;

    background-color: rgb(255, 255, 255);

    padding: 3em;
    margin-top: 0em;

    padding-top: 10em;

    position: relative;
  }

  .SectionHeads {
    align-items: flex-start;
    margin-bottom: 2em;
    margin-left: 1em;
  }

  .brr {
    display: block;
  }

  .bookFreeDemo {
    border: none;
    border-radius: 10px;

    padding: 0.9em 1.5em;

    font-family: "poppins", sans-serif;
    font-size: clamp(0.6rem, 1.3vw, 1.3rem);
    font-weight: 800;

    text-transform: uppercase;

    margin-top: 1em;

    background: linear-gradient(180deg, #ffc143 0%, #bf7300 100%);
    color: #000;
    cursor: pointer;

    transition: all 0.5s;
  }
}
