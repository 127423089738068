.aboutMePanel {
  width: 100%;
  min-height: calc(100vh - 100px);

  //   background-color: rgb(173, 173, 173);

  padding-top: 2em;

  //   overflow: hidden;
}

.innerAboutMePanel {
  width: 100%;

  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  gap: 2em;
}

.aboutMeNavBar {
  width: 100%;

  margin: 0 auto;
}

.aboutMeNavBarLinkWrap {
  width: 90%;

  display: flex;
  flex-direction: row;

  justify-content: space-around;
  align-items: center;

  list-style: none;

  //   background-color: gray;
}

.aboutMeLinks a {
  text-decoration: none;
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.95rem, 1.2vw, 1.2rem);
  //   font-weight: 400;
  color: #000;
  padding: 0.5em 1em;
}

.aboutMeLinks .aboutMeLink:hover,
.aboutMeLinks .aboutMeLink:focus {
  background-color: #dddddd;
  font-weight: 600;
}

.aboutMeLinkRoutes {
  width: 100%;
  height: calc(100vh - 240px);
  padding: 2em 1.6em;

  overflow-y: scroll;
}

@media (max-width: 1500px) {
  .aboutMeNavBarLinkWrap {
    width: 100%;

    display: flex;
    flex-direction: row;

    justify-content: space-around;
    align-items: center;

    list-style: none;

    //   background-color: gray;
  }
}

@media (max-width: 1350px) {
  .aboutMeLinks a {
    text-decoration: none;
    font-family: "Space Grotesk", sans-serif;
    font-size: clamp(0.85rem, 1.2vw, 1.2rem);
    //   font-weight: 400;
    color: #000;
    padding: 0.5em 0.6em;
  }
}

.dropDwnMenu {
  display: none;
}

@media (max-width: 1140px) {
  .aboutMeNavBar {
    width: 100%;

    margin: 0 auto;

    display: flex;
    flex-direction: row;

    justify-content: flex-end;
    align-items: center;

    padding: 0em 2em;
  }

  .aboutMeNavBarLinkWrap {
    width: 20%;
    min-width: 210px;
    height: 40%;
    min-height: 235px;

    display: flex;
    flex-direction: column;

    justify-content: space-around;
    align-items: center;

    list-style: none;

    // gap: 0.5em;

    position: fixed;
    top: 10em;

    background-color: rgb(0, 11, 73);

    border-radius: 10px;

    padding: 1em 0em;

    animation: menuReveal 0.2s linear;

    cursor: pointer;
  }

  @keyframes menuReveal {
    0% {
      height: 0px;
    }
    100% {
      height: 250px;
    }
  }

  .aboutMeLinks a {
    text-decoration: none;
    font-family: "Space Grotesk", sans-serif;
    font-size: clamp(0.82rem, 1.1vw, 1.1rem);
    //   font-weight: 400;
    color: #ffffff;
    padding: 0.5em 1em;
  }

  .aboutMeLinks .aboutMeLink:hover,
  .aboutMeLinks .aboutMeLink:focus {
    background-color: #dddddd;
    color: rgb(0, 11, 73);
    font-weight: 600;
  }

  .dropDwnMenu {
    display: block;
  }

  .dropDwnIcon {
    width: 30px;
    aspect-ratio: 1/1;

    border-radius: 50px;
    background-color: rgb(13, 0, 87);

    cursor: pointer;

    padding: 0.3em;
  }

  .dropDwnIcon:hover,
  .dropDwnIcon:focus {
    background-color: rgb(29, 0, 192);
  }

  .dropDwnIcon2 {
    transform: rotateY(180);
  }

  .DISPLAYNone {
    display: none;
  }
}
