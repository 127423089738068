section {
  width: 100%;
  margin: 0 auto;
}

.innerSeventhSection {
  width: 100%;

  //   background-color: rgb(245, 166, 166);

  padding: 2em;
}

.circlePictureWrap {
  width: 100%;

  //   background-color: aqua;
  position: relative;
}

.firstCircle {
  width: 100%;
  aspect-ratio: 1/1;

  min-width: 290px;

  border-radius: 50%;

  border: 0.5px solid gray;
  position: relative;
}

.seventhHeroPicture {
  width: 90%;
  aspect-ratio: 1/1;

  min-width: 260px;

  border-radius: 50%;

  object-fit: cover;

  position: absolute;
  bottom: 0.2em;
  right: 0.3em;
}

.circlesSeventh {
  width: 30px;
  aspect-ratio: 1/1;

  background-color: #105755;

  border-radius: 50%;

  position: absolute;
  top: 45%;
  left: -0.5em;
}

.circlesSeventh2 {
  width: 30px;
  aspect-ratio: 1/1;

  background-color: #ffc143;

  border-radius: 50%;

  position: absolute;
  top: 80%;
  right: 1.2em;
}

.seventhTextSection {
  width: 100%;

  margin: 0 auto;
}

.weCanHelp {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(1.2rem, 1.4vw, 1.4rem);
  font-weight: 400;

  text-align: center;

  margin-top: 2.5em;

  color: #dc9402;
}

.letsShowYou {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(1.45rem, 1.6vw, 1.6rem);
  font-weight: 500;

  text-align: center;

  margin-top: 0.2em;

  color: #000000;
}

.seventhTextSectionParagraph {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.8rem, 1.6vw, 1.6rem);
  font-weight: 500;

  text-align: center;

  margin-top: 1.7em;

  color: #000000;
}

.btnSeventhSec {
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.bookFreeBtn {
  width: 80%;
  padding: 1.5em;
  margin-top: 1em;

  border: none;

  background-color: #ffc143;

  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(1rem, 1.6vw, 1.6rem);
  font-weight: 800;

  transition: all 0.5s;
}

.bookFreeBtn:hover {
  background-color: #c29130;
}

@media (min-width: 530px) {
  .circlePictureWrap {
    width: 85%;

    // background-color: aqua;
    position: relative;

    margin: 0 auto;
  }

  .firstCircle {
    width: 100%;
    aspect-ratio: 1/1;

    min-width: 290px;

    border-radius: 50%;

    border: 0.5px solid gray;
    position: relative;
  }

  .seventhHeroPicture {
    width: 90%;
    aspect-ratio: 1/1;

    min-width: 260px;

    border-radius: 50%;

    object-fit: cover;

    position: absolute;
    bottom: 0.2em;
    right: 0.3em;
  }

  .circlesSeventh {
    width: 30px;
    aspect-ratio: 1/1;

    background-color: #105755;

    border-radius: 50%;

    position: absolute;
    top: 45%;
    left: -0.5em;
  }

  .circlesSeventh2 {
    width: 30px;
    aspect-ratio: 1/1;

    background-color: #ffc143;

    border-radius: 50%;

    position: absolute;
    top: 80%;
    right: 1.2em;
  }
}

@media (min-width: 630px) {
  .circlePictureWrap {
    width: 65%;

    // background-color: aqua;
    position: relative;

    margin: 0 auto;
  }
}

@media (min-width: 930px) {
  .innerSeventhSection {
    width: 100%;

    //   background-color: rgb(245, 166, 166);

    padding: 2em;

    display: flex;
    flex-direction: row-reverse;

    justify-content: center;
    align-items: center;

    margin: 0 auto;

    margin-top: 2em;
    margin-bottom: 4em;
  }
  .circlePictureWrap {
    width: 35%;

    // background-color: aqua;
    position: relative;

    margin: 0 auto;
  }

  .seventhTextSection {
    width: 50%;

    margin: 0 auto;
  }

  .weCanHelp {
    font-family: "Space Grotesk", sans-serif;
    font-size: clamp(1.2rem, 1.4vw, 1.4rem);
    font-weight: 400;

    text-align: left;

    margin-top: 0em;

    color: #dc9402;
  }

  .letsShowYou {
    font-family: "Space Grotesk", sans-serif;
    font-size: clamp(1.45rem, 1.6vw, 1.6rem);
    font-weight: 500;

    text-align: left;

    margin-top: 0.2em;

    color: #000000;
  }

  .seventhTextSectionParagraph {
    font-family: "Space Grotesk", sans-serif;
    font-size: clamp(0.8rem, 1.6vw, 1.6rem);
    font-weight: 500;

    text-align: left;

    margin-top: 1.7em;

    color: #000000;
  }

  .btnSeventhSec {
    width: 100%;

    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .bookFreeBtn {
    width: 80%;
    padding: 1.5em;
    margin-top: 1em;

    border: none;

    background-color: #ffc143;

    font-family: "Space Grotesk", sans-serif;
    font-size: clamp(1rem, 1.6vw, 1.6rem);
    font-weight: 800;

    transition: all 0.5s;
  }
}

@media (min-width: 1000px) {
  .letsShowYou {
    font-family: "Space Grotesk", sans-serif;
    font-size: clamp(1.9rem, 2.2vw, 2.2rem);
    font-weight: 600;

    text-align: left;

    margin-top: 0.2em;

    color: #000000;
  }

  .seventhTextSectionParagraph {
    font-family: "Space Grotesk", sans-serif;
    font-size: clamp(1rem, 1.6vw, 1.6rem);
    font-weight: 500;

    text-align: left;

    margin-top: 1.7em;

    color: #000000;
  }
}

@media (min-width: 1300px) {
  .seventhTextSection {
    width: 35%;

    margin: 0 auto;
  }
}
