.WeCareMessageHead {
  width: 100%;

  margin: 0 auto;

  position: fixed;
  top: 0;
  left: 0;

  background-color: #fff;
}

.innerWeCareMessageHead {
  width: 100%;

  padding: 1em 2em;

  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;
}

.weCareLogoImg {
  width: 130px;

  z-index: 99999;
}

.weCareMessageNav {
  width: 100%;

  //   background-color: gray;

  display: flex;
  flex-direction: row;

  justify-content: end;
  align-items: center;

  gap: 2.5em;
}

.weCareMessageList {
  list-style: none;

  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 1.5em;
}

.weCareLink .weCareLnk {
  font-family: "poppins", sans-serif;
  font-size: clamp(0.75rem, 1.15vw, 1.15rem);

  text-decoration: none;

  color: #000;
}

.weCareMessageNavBtn {
  background: #585a60;

  font-family: "poppins", sans-serif;
  font-size: clamp(0.75rem, 1.2vw, 1.2rem);
  font-weight: 600;

  color: #fff;

  border: none;
  border-radius: 6px;

  padding: 1em;
}

.weCareMessMenu {
  display: none;
}

@media (max-width: 820px) {
  .weCareLogoImg {
    width: 110px;

    z-index: 99999;
  }

  .weCareMessageNav {
    width: 100%;
    min-height: 100vh;
    min-height: 100dvh;
    //   background-color: gray;

    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;

    gap: 2.5em;

    position: fixed;
    top: 0em;
    left: 0em;
    background-color: #fff;

    margin: 0 auto;
  }

  .weCareMessageList {
    width: 100%;
    list-style: none;

    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;

    gap: 1.5em;

    margin: 0 auto;

    // background-color: red;
  }

  .weCareLink .weCareLnk {
    font-family: "poppins", sans-serif;
    font-size: clamp(1.2rem, 1.4vw, 1.4rem);

    text-decoration: none;

    color: #000;
  }

  .weCareMessMenu {
    width: 40px;
    aspect-ratio: 1/1;

    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: flex-end;

    gap: 0.22em;

    cursor: pointer;

    // background-color: red;

    z-index: 99999;

    position: relative;
  }

  .weCareMessline {
    width: 25px;
    height: 2px;

    border-radius: 10px;
    background-color: rgb(0, 0, 0);
  }

  .midMess {
    width: 20px;
  }

  .displayNone {
    display: none;
  }

  .closeClientMessNav .weCareMessline:nth-child(1) {
    width: 20px;
    position: absolute;
    transform-origin: 50% 50%;
    transform: translate(5%) rotate(-45deg);
    transition: 0.5s;
  }

  .closeClientMessNav .weCareMessline:nth-child(2) {
    display: none;
  }

  .closeClientMessNav .weCareMessline:nth-child(3) {
    width: 20px;
    position: absolute;
    transform-origin: 50% 50%;

    transform: translate(5%) rotate(45deg);
    transition: 0.5s;
  }
}
