header {
  width: 100%;

  //   background-color: grey;

  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  z-index: 99999;

  position: fixed;

  background-color: #fff;
}

.innerHeader {
  width: 90%;
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  padding: 2em 0em;

  z-index: 99999;
}

.logo {
  width: 8vw;
  min-width: 120px;
  object-fit: contain;

  cursor: pointer;
}

.homePageNavbar {
  width: 100%;

  //   background-color: red;

  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  align-items: center;
}

.homePadeNavbarLinkWrap {
  width: 100%;
  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  align-items: center;

  gap: 2em;

  list-style: none;

  //   background-color: green;
}

.homePageNavbarLink a {
  text-decoration: none;
  color: #000;

  font-family: "poppins", sans-serif;
  font-size: clamp(0.8rem, 1.1vw, 1.1rem);
  font-weight: 500;

  transition: all 0.5s;
}

.homePageNavbarLink a:hover,
.homePageNavbarLink a:focus {
  color: rgb(255, 174, 0);
}

.bookADemo {
  width: 9vw;
  min-width: 100px;
  padding: 1em 0.5em;

  font-family: "poppins", sans-serif;
  font-size: clamp(0.6rem, 1.1vw, 1.1rem);
  font-weight: 700;

  border: none;
  border-radius: 10px;

  background-color: #0f2255;
  color: #fff;

  cursor: pointer;

  transition: all 0.5s;
}

.bookADemo:hover {
  background-color: #15348b;
}

.menu {
  display: none;
}

@media (max-width: 830px) {
  header {
    width: 100%;

    margin: 0 auto;
  }

  .innerHeader {
    width: 100%;

    display: flex;
    flex-direction: row;

    align-items: center;
    justify-content: space-between;

    padding: 1em;

    //   background-color: red;
  }

  .logo {
    min-width: 90px;

    z-index: 9;

    // background-color: #960000;
  }

  .homePageNavbar {
    width: 100%;
    height: 100%;
    min-height: 100dvw;

    position: fixed;
    top: 3em;
    right: 0;

    display: flex;
    flex-direction: row;

    justify-content: flex-end;
    align-items: flex-start;

    background-color: rgb(255, 255, 255);

    overflow-x: hidden;

    transition-timing-function: ease-in;
    transition: all 0.5s;

    animation: navdrop 0.6s linear 1;
  }

  .homePadeNavbarLinkWrap {
    width: 100%;
    display: flex;
    flex-direction: column;

    justify-content: space-between;
    align-items: flex-start;

    gap: 0em;

    list-style: none;

    margin-top: 1em;

    padding: 1em;

    //   background-color: green;
  }

  .homePageNavbarLink a {
    text-decoration: none;
    color: #000;

    font-family: "Poppins", sans-serif;
    font-size: clamp(0.6rem, 0.7rem, 0.7rem);
    font-weight: 400;

    transition: all 0.5s;
  }

  .homePageNavbarLink a:hover,
  .homePageNavbarLink a:focus {
    color: rgb(255, 174, 0);
  }

  .bookADemo {
    width: 12vw;
    padding: 1.5em 1em;

    font-family: "poppins", sans-serif;
    font-size: clamp(0.6rem, 1.13vw, 1.13rem);
    font-weight: 700;

    border: none;
    border-radius: 10px;

    background-color: #0f2255;
    color: #fff;
  }

  @keyframes navdrop {
    0% {
      transform: translateY(-12%);
      opacity: 0;
    }

    50% {
      transform: translateY(-5%);
      opacity: 0;
    }

    100% {
      transform: translateY(0%);
      opacity: 1;
    }
  }

  .homePageNavbarLink {
    width: 100%;

    color: white;

    padding-top: 0.8em;
    padding-bottom: 1.3em;

    border-bottom: 1px solid rgba(30, 30, 47, 0.1);

    cursor: pointer;

    //   background-color: dimgray;

    animation: linkReveal 0.4s linear 0.1s 1;
  }

  @keyframes linkReveal {
    0% {
      opacity: 0;
    }

    50% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  .noBorder {
    border: none;
  }

  /* .homePageNavbarLink::after {
        content: "";
      
        width: 88vw;
        height: 1px;
      
        display: block;
      
        background-color: red;
      
        margin-top: 0.9em;
      } */

  .menu {
    width: 40px;
    aspect-ratio: 1/1;

    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: flex-end;

    gap: 0.22em;

    cursor: pointer;
  }

  .line {
    width: 25px;
    height: 2px;

    border-radius: 10px;
    background-color: rgb(0, 0, 0);
  }

  .menu .mid {
    width: 16px;
  }

  .bookADemo {
    width: 100%;

    border-radius: 10px;

    border: none;
    background-color: #fff;

    padding: 0.8em;
    background-color: #0047b8;

    color: white;

    font-size: clamp(0.8rem, 0.9vw, 0.9rem);
    font-family: "poppins", sans-serif;
    font-weight: 500;

    transition: all 0.5s;
  }

  .bookADemo:hover {
    background-color: #022764;
  }

  .displayNone {
    display: none;
  }

  .innerHeader .close .line:nth-child(1) {
    width: 20px;
    position: absolute;
    transform-origin: 50% 50%;
    transform: translate(5%) rotate(-45deg);
    transition: 0.5s;
  }

  .innerHeader .close .line:nth-child(2) {
    display: none;
  }

  .innerHeader .close .line:nth-child(3) {
    width: 20px;
    position: absolute;
    transform-origin: 50% 50%;

    transform: translate(5%) rotate(45deg);
    transition: 0.5s;
  }
}
