footer {
  width: 100%;
  margin: 0 auto;
}

.innerEightSection {
  width: 100%;

  padding: 2em;

  background-color: #1b2034;

  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;
}

.logoAndSupport {
  width: 100%;
}

.footerLogo {
  width: 120px;
}

.supportText {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.75rem, 1vw, 1rem);
  font-weight: 200;
  color: #fff;
}

.listFoots {
  width: 100%;

  //   background-color: rgb(73, 73, 73);

  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 2em;
}

.footerList {
  width: 100%;

  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  //   background-color: aqua;

  //   border-bottom: 1px solid rgb(68, 68, 68);
  margin-top: 0.7em;
}

.footListHeads {
  width: 100%;

  margin: 0 auto;

  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  border-bottom: 1px solid rgb(68, 68, 68);

  padding-bottom: 0.6em;
}

.listHeadText {
  font-family: "poppins", sans-serif;
  font-size: clamp(0.7rem, 1.1vw, 1.1rem);
  font-weight: 600;

  text-transform: uppercase;

  color: #fff;
}

.footArrowDown {
  width: 17px;
  transition: 0.3s;

  //   transform: rotate(180deg);
}

.footArrowDown:hover {
  transform: rotate(180deg);
  //   background-color: red;
}

.footListing {
  width: 100%;

  list-style: none;

  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  gap: 0.4em;

  margin-top: 0.4em;
  margin-bottom: 1em;

  overflow: hidden;
}

/* .nonActive {
  display: none;
  transition: 0.5s;

  //   background-color: red;
} */

.footListed .ourProdLinks {
  text-decoration: none;
  color: #fff;

  font-family: "poppins", sans-serif;
  font-size: clamp(0.8rem, 1.1vw, 1.1rem);
  font-weight: 300;
}

.getAppWrap {
  width: 100%;
  margin: 0 auto;

  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  gap: 0.5em;

  margin-top: 2em;
}

.getItOn {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(1.1rem, 1.4vw, 1.4rem);

  text-align: center;

  color: #fff;
}

.downloadBtn {
  width: 110px;
  margin-right: 0.5em;
}

@media (min-width: 850px) {
  .innerEightSection {
    width: 100%;

    padding: 3em 1em;

    background-color: #1b2034;

    display: flex;
    flex-direction: row;

    justify-content: flex-start;
    align-items: flex-start;

    position: relative;
  }

  .logoAndSupport {
    width: 250px;
  }

  .getAppWrap {
    width: 50%;
    margin: 0 auto;

    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: flex-start;

    gap: 0.5em;

    margin-top: 2em;

    position: absolute;
    top: 6em;
  }

  .getItOn {
    font-family: "Space Grotesk", sans-serif;
    font-size: clamp(0.7rem, 1.4vw, 1.4rem);

    text-align: left;

    color: #fff;

    // background-color: red;
  }

  .listFoots {
    width: 100%;

    //   background-color: rgb(73, 73, 73);

    display: flex;
    flex-direction: row;

    justify-content: flex-start;
    align-items: flex-start;

    margin-top: 0em;
  }

  .downloadBtn {
    width: 80px;
    margin-right: 0.5em;
  }

  .downloadBtn2 {
    width: 80px;
    margin-right: 0.5em;
  }

  .footerList {
    width: 100%;

    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;

    //   background-color: aqua;

    //   border-bottom: 1px solid rgb(68, 68, 68);
    margin-top: 0.7em;

    // margin-left: 1em;
  }

  .footListHeads {
    width: 100%;

    margin: 0 auto;

    display: flex;
    flex-direction: row;

    justify-content: space-between;
    align-items: center;

    border-bottom: none;

    padding-bottom: 0.6em;
  }

  .listHeadText {
    font-family: "poppins", sans-serif;
    font-size: clamp(0.55rem, 1.1vw, 1.1rem);
    font-weight: 600;

    text-transform: uppercase;

    color: #fff;
  }

  .footArrowDown {
    width: 17px;
    transition: 0.3s;

    //   transform: rotate(180deg);

    display: none;
  }

  .footArrowDown:hover {
    transform: rotate(180deg);
    //   background-color: red;
  }

  .footListing {
    width: 100%;

    list-style: none;

    display: flex;
    flex-direction: column;

    justify-content: flex-start;
    align-items: flex-start;

    gap: 0.8em;

    margin-top: 0.4em;
    margin-bottom: 1em;

    overflow: hidden;
  }

  .nonActive {
    display: flex;
    transition: 0.5s;

    //   background-color: red;
  }

  .footListed .ourProdLinks {
    text-decoration: none;
    color: #fff;

    font-family: "poppins", sans-serif;
    font-size: clamp(0.65rem, 1.1vw, 1.1rem);
    font-weight: 300;
  }
}
