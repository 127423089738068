.ClientBoardHeader {
  width: 100%;

  margin: 0 auto;

  z-index: 999999;

  background: #fff;

  position: fixed;
  top: 0;

  padding-bottom: 1em;

  backdrop-filter: blur(1rem);
}

.innerClientBoardHeader {
  width: 100%;

  //   background-color: gray;

  padding: 1em 2em;
}

.clientHeaders {
  width: 100%;

  //   background-color: rgb(139, 139, 139);

  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;
}

.logoImg {
  width: 130px;
}

.clientNav {
  width: 40%;
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  //   background-color: yellow;
}

.clientNavList {
  width: 50%;

  //   background-color: red;

  display: flex;
  flex-direction: row;

  justify-content: space-between;

  gap: 0.5em;
  list-style: none;
}

.clientLink a {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.9rem, 1vw, 1rem);

  text-decoration: none;
  color: #000;
}

.clientLink a:hover,
.clientLink a:focus {
  color: rgb(165, 165, 165);
  font-weight: 500;
}

.syncImg {
  width: 50px;
}

.expand {
  width: 50px;
}

.notifyAndAcc {
  width: 20%;
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  //   background-color: yellow;
}

.notify {
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
}

.syncMins {
  width: 22px;
  aspect-ratio: 1/1;

  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.7rem, 1vw, 1rem);
  font-weight: 500;

  border-radius: 50px;
  background-color: #d9d9d9;

  display: grid;
  place-content: center;

  color: rgb(173, 134, 5);

  margin-top: 0.7em;
}

.syncImg {
  width: 30px;

  background-color: rgb(255, 203, 34);
  border-radius: 50px;
}

.syncMin {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.9rem, 1vw, 1rem);
  font-weight: 500;
}

.clientAccName {
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;
}

.acName {
  width: 50px;
  aspect-ratio: 1/1;

  border-radius: 50px;

  background-color: #847b7b;

  display: grid;
  place-content: center;

  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(1rem, 1.6vw, 1.6rem);
  font-weight: 500;

  color: #fff;
}

.expand {
  width: 17px;
}

.ClientBoardHeader .clientBoardMenu {
  display: none;
}

@media (max-width: 1140px) {
  .ClientBoardHeader {
    width: 100%;

    margin: 0 auto;

    z-index: 999999;

    background: #fff;

    position: fixed;
    top: 0;

    padding-bottom: 0.3em;

    backdrop-filter: blur(1rem);
  }

  .innerClientBoardHeader {
    width: 100%;

    //   background-color: gray;

    padding: 0.5em 2em;
  }
  .clientNav {
    width: 50%;
    display: flex;
    flex-direction: row;

    justify-content: space-between;
    align-items: center;

    //   background-color: yellow;
  }
  .clientNavList {
    width: 60%;

    // background-color: red;

    display: flex;
    flex-direction: row;

    justify-content: space-between;

    list-style: none;
  }

  .syncMin {
    font-family: "Space Grotesk", sans-serif;
    font-size: clamp(0.7rem, 1vw, 1rem);
    font-weight: 500;
  }

  .notifyAndAcc {
    width: 30%;
    display: flex;
    flex-direction: row;

    justify-content: space-between;
    align-items: center;

    //   background-color: yellow;
  }
}

@media (max-width: 760px) {
  .ClientBoardHeader {
    width: 100%;

    margin: 0 auto;

    z-index: 999999;

    background: #fff;

    position: fixed;
    top: 0;

    padding-bottom: 1.2em;

    backdrop-filter: blur(1rem);
  }

  .innerClientBoardHeader {
    width: 100%;

    //   background-color: gray;

    padding: 1em 2em;
  }

  .clientHeaders {
    width: 100%;

    display: flex;
    flex-direction: row;

    justify-content: space-around;
  }

  .clientNav {
    width: 100%;
    // min-height: 100vh;

    display: flex;
    flex-direction: row;

    justify-content: space-between;
    align-items: flex-start;

    // background-color: yellow;

    position: fixed;
    top: 0em;

    // z-index: 9999;
  }

  .logoImg {
    width: 100px;
    position: absolute;
    top: 0.5em;
    left: 2em;

    // z-index: -1;
  }

  .clientNavList {
    width: 50%;
    min-height: 40vh;
    background-color: rgb(196, 196, 196);

    display: flex;
    flex-direction: column;

    justify-content: flex-start;
    align-items: flex-end;

    gap: 1em;

    border-radius: 10px;

    list-style: none;

    margin-inline-start: auto;

    margin-top: 3em;

    padding-right: 1em;
    padding-top: 1em;

    position: absolute;
    right: 1em;
  }

  .notifyAndAcc {
    width: 50%;
    display: flex;
    flex-direction: column-reverse;

    justify-content: flex-end;
    align-items: flex-end;

    // background-color: yellow;

    position: fixed;
    top: 14em;
    right: 2em;
  }

  .syncMin {
    font-family: "Space Grotesk", sans-serif;
    font-size: clamp(0.65rem, 1vw, 1rem);
    font-weight: 500;
  }

  .syncMins {
    width: 20px;
    aspect-ratio: 1/1;

    font-family: "Space Grotesk", sans-serif;
    font-size: clamp(0.55rem, 1vw, 1rem);
    font-weight: 500;

    border-radius: 50px;
    background-color: #d9d9d9;

    display: grid;
    place-content: center;

    color: rgb(173, 134, 5);

    margin-top: 0.7em;
  }

  .syncImg {
    width: 20px;

    background-color: rgb(255, 203, 34);
    border-radius: 50px;
  }

  .acName {
    width: 30px;
    aspect-ratio: 1/1;

    border-radius: 50px;

    background-color: #847b7b;

    display: grid;
    place-content: center;

    font-family: "Space Grotesk", sans-serif;
    font-size: clamp(0.7rem, 1.6vw, 1.6rem);
    font-weight: 500;

    color: #fff;
  }

  .expand {
    width: 10px;
  }

  .ClientBoardHeader .clientBoardMenu {
    display: block;

    width: 26px;
    aspect-ratio: 1/1;

    // background-color: red;

    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: flex-end;

    gap: 0.15em;

    cursor: pointer;

    position: fixed;
    top: 1em;
    right: 2em;
  }

  .clientBoardMenu .clientBoardMenuLine {
    width: 25px;
    height: 2px;

    background-color: #000;
  }

  .clientBoardMenu .clientBoardMenuLine:nth-child(2) {
    width: 15px;
  }

  .displayNone {
    display: none;
  }

  .close .clientBoardMenuLine:nth-child(1) {
    width: 20px;
    position: absolute;
    transform-origin: 50% 50%;
    transform: translate(5%) rotate(-45deg);
    transition: 0.5s;
  }

  .close .clientBoardMenuLine:nth-child(2) {
    display: none;
  }

  .close .clientBoardMenuLine:nth-child(3) {
    width: 20px;
    position: absolute;
    transform-origin: 50% 50%;

    transform: translate(5%) rotate(45deg);
    transition: 0.5s;
  }
}
