section {
  width: 100%;
  margin: 0 auto;
}

.innerFifthSection {
  background-color: gray;
  width: 100%;

  padding: 2em;

  overflow-x: hidden;

  margin: 1em 0em;
  margin-bottom: 4em;
}

.testimonials {
  font-family: "space grotesk", sans-serif;
  font-size: clamp(1.1rem, 1.5vw, 1.5rem);
  font-weight: 400;

  color: #fff;
  text-align: center;

  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: flex-end;

  gap: 0.3em;

  & .angleDown {
    width: 12px;
  }
}

.testimonialSlideWrap {
  width: 100%;

  margin: 0 auto;

  //   background-color: yellowgreen;

  border-radius: 10px;

  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  gap: 1em;

  margin-top: 1.5em;

  overflow-x: hidden;
}

.testimoy {
  width: 100%;
  min-width: 300px;

  background-color: #fff;

  padding: 2em;

  border-radius: 10px;
}

.testimonyPixandName {
  width: 100%;

  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;
}

.testimonyPicture {
  width: 60px;
  aspect-ratio: 1/1;

  object-fit: cover;

  border-radius: 50px;
}

.bizName {
  margin-left: 1em;

  font-family: "space grotesk", sans-serif;
  font-size: clamp(1rem, 1.1vw, 1.1rem);
  font-weight: 600;
}

.testimonyText {
  margin-top: 1.5em;

  font-family: "space grotesk", sans-serif;
  font-size: clamp(0.7rem, 1vw, 1rem);
  font-weight: 500;
}

.readCaseStudy {
  margin-top: 0.8em;

  font-family: "space grotesk", sans-serif;
  font-size: clamp(0.9rem, 1.1vw, 1.1rem);
  font-weight: 600;
}

.slideBtn {
  width: 100%;

  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  margin-top: 1.5em;
}

.slideBtn .angleLeft {
  width: 45px;
  aspect-ratio: 1/1;

  padding: 0.5em;
  background-color: #d9d9d9;

  border-radius: 50px;

  margin-left: 0.5em;

  z-index: 1;

  transition: all 0.5s;
}

.slideBtn .angleLeft:hover {
  background-color: #e9e9e9;
}

.slideBtn .angleRight {
  width: 45px;
  aspect-ratio: 1/1;

  padding: 0.5em;
  background-color: #1b2034;

  border-radius: 50px;

  margin-left: -0.5em;

  transition: all 0.5s;
}

.slideBtn .angleRight:hover {
  background-color: #404b75;
}
