.ClientBoardSecondHeader {
    width: 100%;
  
    //   background-color: grey;
  
    margin-top: 3em;
  
    //   position: relative;
    //   z-index: -1;
  
    display: flex;
    flex-direction: row;
  
    justify-content: center;
    align-items: center;
  }
  
  .innerClientBoardSecondHeader {
    width: 100%;
  
    padding: 1em 2em;
    margin: 0 auto;
  }