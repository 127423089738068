.dashBoard {
  width: 100%;
  //   overflow: hidden;
}

.sideBarAndRightBar {
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: flex-start;
}
.title{
  font-family: "Space Grotesk", sans-serif;
  font-size: 24px;
  font-weight: 500;

  color: #514f4f;
  border-bottom: 2px solid #514f4f;
  flex:1; 
}
.boxline{
 border-color: aliceblue;
 background-color: azure;
 border-width: 1px;
 margin-top: 1.5em;
 margin-bottom: 1.5em;

 padding: 0.5em;
    border-radius: 5px;
    border: 1px solid gray;
}
.boxline_caption{
  flex:1;
  font-size: 16px;
  font-weight: 500;
}

