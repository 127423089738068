section {
  width: 100%;
  margin: 0 auto;
}

.innerFourthSection {
  width: 100%;
  margin: 0 auto;

  padding: 2em;

  margin: 1em 0;
}

.pictureHead {
  width: 100%;
  position: relative;
}

.getYourResult {
  width: 70%;
  font-family: "poppins", sans-serif;
  font-size: clamp(0.5rem, 1vw, 1rem);
  font-weight: 500;

  background-color: #1b2034;
  color: #fff;

  padding: 1.3em 0em;

  text-align: center;

  position: relative;
  top: 2em;
  left: 2em;
}

.headPix {
  width: 100%;

  height: 33vh;

  object-fit: cover;

  border-radius: 20px;
}

.reportText {
  width: 82%;
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.55rem, 1vw, 1rem);
  font-weight: 400;

  background-color: #a21431;
  color: #fff;

  margin: 0 auto;

  padding: 0.5em;
  margin-top: 2em;

  border-radius: 50px;

  text-align: center;
}

.percentSaves {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(1rem, 1.3vw, 1.3rem);
  font-weight: 600;

  text-align: center;

  margin-top: 1em;

  padding: 0em 1em;
}

.percentSavesParagraph {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.7rem, 1vw, 1rem);
  font-weight: 500;

  text-align: center;

  margin-top: 1em;
  padding: 0em 1em;
}

.btnWrap {
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
}

.saveCalcBtn {
  width: 75%;
  background-color: #1b2034;
  color: #fff;

  border: none;
  border-radius: 10px;

  font-family: "poppins", sans-serif;
  font-size: clamp(0.65rem, 1vw, 1rem);

  padding: 1em 0em;

  text-align: center;

  margin-top: 1.5em;

  transition: all 0.5s;
}

.saveCalcBtn:hover {
  background-color: #1e2d68;
}

@media (min-width: 630px) {
  .pictureHead {
    width: 70%;
    position: relative;

    margin: 0 auto;
  }

  .getYourResult {
    width: 70%;
    font-family: "poppins", sans-serif;
    font-size: clamp(0.7rem, 1vw, 1rem);
    font-weight: 500;

    background-color: #1b2034;
    color: #fff;

    padding: 1.3em 0em;

    text-align: center;

    position: relative;
    top: 2em;
    left: 2em;
  }

  .headPix {
    width: 100%;
    height: 36vh;

    object-fit: cover;

    border-radius: 20px;
  }

  .reportText {
    width: 60%;
    font-family: "Space Grotesk", sans-serif;
    font-size: clamp(0.7rem, 1vw, 1rem);
    font-weight: 400;

    background-color: #a21431;
    color: #fff;

    margin: 0 auto;

    padding: 0.5em;
    margin-top: 2em;

    border-radius: 50px;

    text-align: center;
  }

  .percentSaves {
    width: 65%;
    font-family: "Space Grotesk", sans-serif;
    font-size: clamp(1rem, 1.3vw, 1.3rem);
    font-weight: 600;

    text-align: center;
    margin: 0 auto;

    margin-top: 1em;

    padding: 0em 1em;
  }

  .percentSavesParagraph {
    width: 75%;
    font-family: "Space Grotesk", sans-serif;
    font-size: clamp(0.7rem, 1vw, 1rem);
    font-weight: 500;

    text-align: center;

    margin: 0 auto;

    margin-top: 1.5em;
    padding: 0em 1em;
  }

  .btnWrap {
    display: flex;
    flex-direction: row;

    justify-content: center;
    align-items: center;
  }

  .saveCalcBtn {
    width: 45%;
    background-color: #1b2034;
    color: #fff;

    border: none;
    border-radius: 10px;

    font-family: "poppins", sans-serif;
    font-size: clamp(0.65rem, 1vw, 1rem);

    padding: 1em 0em;

    text-align: center;

    margin-top: 2em;

    transition: all 0.5s;
  }
}

@media (min-width: 930px) {
  .innerFourthSection {
    width: 100%;
    margin: 0 auto;

    padding: 2em;

    margin: 2em 0;

    display: flex;
    flex-direction: row;

    justify-content: space-between;
    align-items: center;

    gap: 2em;
  }

  .pictureHead {
    width: 100%;
    position: relative;

    margin: 0 auto;

    // background-color: red;
  }

  .textAreaWrap {
    width: 100%;

    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: flex-start;
  }

  .getYourResult {
    width: 70%;
    font-family: "poppins", sans-serif;
    font-size: clamp(0.6rem, 1vw, 1rem);
    font-weight: 500;

    background-color: #1b2034;
    color: #fff;

    padding: 1.3em 0em;

    text-align: center;

    position: relative;
    top: 2em;
    left: 2em;
  }

  .reportText {
    width: 80%;
    font-family: "Space Grotesk", sans-serif;
    font-size: clamp(0.7rem, 1vw, 1rem);
    font-weight: 400;

    background-color: #a21431;
    color: #fff;

    margin: 0;

    padding: 0.5em;
    margin-top: 2em;

    border-radius: 50px;

    text-align: center;
  }

  .percentSaves {
    width: 100%;
    font-family: "Space Grotesk", sans-serif;
    font-size: clamp(1.15rem, 1.3vw, 1.3rem);
    font-weight: 600;

    text-align: left;
    margin: 0;

    margin-top: 1em;

    padding: 0em 0em;
  }

  .percentSavesParagraph {
    width: 100%;
    font-family: "Space Grotesk", sans-serif;
    font-size: clamp(0.8rem, 1vw, 1rem);
    font-weight: 500;

    text-align: left;

    margin: 0;

    margin-top: 1.5em;
    padding: 0em 0em;
  }

  .btnWrap {
    width: 100%;
    display: flex;
    flex-direction: row;

    justify-content: flex-start;
    align-items: center;
  }

  .saveCalcBtn {
    width: 55%;
    background-color: #1b2034;
    color: #fff;

    border: none;
    border-radius: 10px;

    font-family: "poppins", sans-serif;
    font-size: clamp(0.65rem, 1vw, 1rem);

    padding: 1em 0em;

    text-align: center;

    margin-top: 2em;

    transition: all 0.5s;
  }
}

@media (min-width: 1180px) {
  .headPix {
    width: 90%;
    height: 50vh;

    object-fit: cover;

    border-radius: 20px;
  }

  .textAreaWrap {
    width: 75%;
  }

  .reportText {
    width: calc(70% - 30px);
    // min-width: 300px;
    font-family: "Space Grotesk", sans-serif;
    font-size: clamp(0.65rem, 1vw, 1rem);
    font-weight: 400;

    background-color: #a21431;
    color: #fff;

    margin: 0;

    padding: 0.5em;
    margin-top: 2em;

    border-radius: 50px;

    text-align: center;
  }

  .percentSaves {
    width: 100%;
    font-family: "Space Grotesk", sans-serif;
    font-size: clamp(1.3rem, 1.7vw, 1.7rem);
    font-weight: 600;

    text-align: left;
    margin: 0;

    margin-top: 1em;

    padding: 0em 0em;
  }

  .percentSavesParagraph {
    width: 100%;
    font-family: "Space Grotesk", sans-serif;
    font-size: clamp(0.85rem, 1vw, 1rem);
    font-weight: 500;

    text-align: left;

    margin: 0;

    margin-top: 1.5em;
    padding: 0em 0em;
  }

  .btnWrap {
    width: 100%;
    display: flex;
    flex-direction: row;

    justify-content: flex-start;
    align-items: center;
  }

  .saveCalcBtn {
    width: calc(55% - 40px);
    background-color: #1b2034;
    color: #fff;

    border: none;
    border-radius: 10px;

    font-family: "poppins", sans-serif;
    font-size: clamp(0.6rem, 1.2vw, 1.2rem);

    padding: 1em 0em;

    text-align: center;

    margin-top: 2em;

    transition: all 0.5s;
  }
}

@media (min-width: 1300px) {
  .pictureHead {
    width: 50%;
    position: relative;

    margin: 0 auto;

    // background-color: red;
  }

  .headPix {
    width: 80%;
    height: 50vh;

    object-fit: cover;

    border-radius: 20px;
  }

  .getYourResult {
    width: 60%;
    font-family: "poppins", sans-serif;
    font-size: clamp(0.6rem, 1vw, 1rem);
    font-weight: 500;

    background-color: #1b2034;
    color: #fff;

    padding: 1.3em 0em;

    text-align: center;

    position: relative;
    top: 2em;
    left: 2em;
  }

  .textAreaWrap {
    width: 40%;
  }
}
