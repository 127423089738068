.RightMainPanel {
  width: 80%;
  min-height: calc(100vh - 100px);
  //   background-color: red;

  margin: 0;

  overflow: hidden;
}
.generalDetails {
    width: 100%;
  
    //   background-color: grey;
  
    //   overflow: hidden;
  }
.header {
    display: flex;
    flex-direction: row;
  align-items: center;
  align-content: center;
  box-sizing: border-box;
   
  }

  .headerText {
    font-family: "Space Grotesk", sans-serif;
    font-size: 24px;
    font-weight: 500;
  
    color: #514f4f;
    border-bottom: 2px solid #514f4f;
    flex:1;
  }
  .editBox {
    display: flex;
    flex-direction: row;
  }
  .areaDetails{
    /* width: 98%;

  background-color: #f3f3f3;
*/
  margin-top: 0.5em; 
  }
  .details{
    margin-top: 0.5em; 
  }
  .loopDetails {
    width: 80%;
    padding: 2em 3em;
  
    display: grid;
    grid-template-columns: repeat(2, minmax(300px, 1fr));
    grid-auto-rows: auto;
  
    grid-column-gap: 2.5em;
    grid-row-gap: 0.5em;
  }
  .groupItem {
    /* background-color: #d9d9d9; 
  
       padding: 0.4em 0em;
  
    display: flex;
    flex-direction: row;
  
    justify-content: space-between;
    align-items: center;*/
    box-sizing: border-box;
    min-width: 0px;
    display: grid;
    grid-template-columns: 1fr 2fr;
    border-bottom: 1px solid rgb(233, 238, 245);
    padding: 1rem;
  }
  
  // .currentStatus:nth-child(5) {
  //   width: 470px;
  // }
  
  .itemLabel {
    box-sizing: border-box;
    font-family: Circular-Std, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    line-height: 1.25rem;
    font-size: 1rem;
    color: rgb(87, 93, 101);
    margin-bottom: 0rem;
  }
  
  .itemText {
    width: 60%;
     background-color: #faf5f5;
  
    padding: 0.6em 0em;
  
    font-family: "Space Grotesk", sans-serif;
    
    font-weight: 500;
    font-size: 1rem;
    padding-left: 1em;
  
    color: #000000 !important;
  }
  .partLinkWrap {
    width: 100%;
  
    //   background-color: aqua;
  
    display: flex;
    flex-direction: row;
  
    justify-content: space-between;
    align-items: center;
  
    gap: 1em;
  }
  .mBtm {
    margin-bottom: 3em;
  }
  .populateTitle {
    font-family: "poppins", sans-serif;
    font-size: clamp(0.8rem, 1.3vw, 1.3rem);
    font-weight: 600;
  }
  
  .pupulateDesc {
    font-family: "poppins", sans-serif;
    font-size: clamp(0.65rem, 1.1vw, 1.1rem);
  
    margin-top: 0.3em;
  }
  
  .addDetlsOrEdit {
    cursor: pointer;
    width: 50;
  }
  .plusIcon {
    width: 17px;
  
    cursor: pointer;
  }
  
  .plusIcon:hover {
    background-color: rgb(255, 238, 0) !important;
    border-radius: 50px;
  
    transition: 0.5s;
  }
  .fakeLink{
    cursor: pointer;
  }
  .fakeLink:hover{
    background-color: rgb(255, 238, 0) !important;
    border-radius: 50px;
    padding-left: 10px;
    padding-right: 10px;
  
    transition: 0.5s;
  }
  .fakeLinkselected{
    border-bottom: #514f4f;
    border-width: 1px;
    border-radius: 50px;
    padding-left: 15px;
    padding-right: 15px;
    transition: 0.5s;
    background-color: rgb(255, 238, 0) !important;
  }

  .lsForm {
    //   background-color: aqua;
    width: 70%;
  }
  
  .formSidePanel {
    //   background-color: rgb(117, 158, 158);
  
    margin-left: -5em;
    margin-inline-end: auto;
  
    position: fixed;
    right: 200px;
  }
  
  .formSidePanelTitle {
    font-family: "Poppins", sans-serif;
    font-size: clamp(0.8rem, 1vw, 1rem);
    font-weight: 500;
  
    //   margin-left: 2em;
    padding: 0em 2em;
  }
  
  .formSidePanelLists {
    list-style: none;
  
    //   background-color: #646464;
    border-left: 1px solid gray;
  
    padding: 0em 2em;
  
    margin-top: 1em;
  }
  
  .formSidePanelLink {
    margin-bottom: 1em;
  }
  
  .fomLink {
    font-family: "Poppins", sans-serif;
    font-size: clamp(0.8rem, 1vw, 1rem);
    font-weight: 500;
  
    text-decoration: none;
  }
  
  @media (max-width: 1500px) {
    .lsForm {
      //   background-color: aqua;
      width: 70%;
    }
  }
  
  .choosePict {
    width: 100%;
  
    //   background-color: yellow;
  
    display: flex;
    flex-direction: row;
  
    justify-content: flex-start;
    align-items: center;
  }
  
  .chooseImgInn {
    width: 80%;
  
    //   background-color: yellowgreen;
  
    padding: 1em;
  
    display: flex;
    flex-direction: column;
  
    justify-content: center;
    align-items: center;
  
    border: 3px solid gray;
    border-radius: 5px;
  }
  
  .chooseImgInnneer {
    width: 80px;
    aspect-ratio: 1/1;
  
    //   background-color: red;
  
    display: flex;
    flex-direction: row;
  
    justify-content: center;
    align-items: center;
  
    border-radius: 50px;
  
    border: 1px solid gray;
  
    position: relative;
  }
  
  .chooseImgIcon {
    width: 50px;
  }
  
  .cameraIcon {
    width: 20px;
  
    position: absolute;
    bottom: 0px;
    right: 0px;
  
    background-color: white;
    border-radius: 50px;
  }
  
  .imgTypeLst {
    margin-top: 0.5em;
  }
  
  .imgTypeTxt {
    font-family: "Poppins", sans-serif;
    font-size: clamp(0.8rem, 0.9vw, 0.9rem);
  }
  
  .dashBoardProfileSection {
    width: 80%;
  
    //   background-color: aqua;
    margin-top: 3em;
  }
  
  .editInput {
    font-family: "Poppins", sans-serif;
    font-size: clamp(0.8rem, 1.4vw, 1.4rem);
    font-weight: 500;
  }
  
  .dashboardCurrentStatus {
    //   width: 100%;
  
    margin-top: 1em;
  }
  
  .currStatus {
    font-family: "Poppins", sans-serif;
    font-size: clamp(0.8rem, 1vw, 1rem);
  }
  
  /* .currentStatusBtns {
    //   margin-top: -0.5em;
  } */
  
  .dashboardCurrentStatusBtn {
    background: #fff;
  
    padding: 0.5em 1em;
  
    font-family: "Poppins", sans-serif;
    font-size: clamp(0.8rem, 0.9vw, 0.9rem);
    //   font-weight: 500;
    border-radius: 5px 0 0 5px;
  
    border: 1px solid grey;
  
    &:hover {
      background-color: #b8fdff;
      border: 2.5px solid #15585a;
      font-weight: 500;
    }
  }
  
  .dashboardCurrentStatusBtn:nth-child(2) {
    border-radius: 0 5px 5px 0;
  
    //   margin-left: -0.2em;
  
    border: 0.5px solid gray;
    &:hover {
      background-color: #b8fdff;
      border: 2.5px solid #15585a;
      font-weight: 500;
    }
  }
  
  .labelAndInputs {
    width: 100%;
  
    //   background-color: red;
    margin-top: 1.5em;
  }
  
  .dashbLable {
    display: block;
  
    font-family: "Poppins", sans-serif;
    font-size: clamp(0.8rem, 1vw, 1rem);
  
    margin-bottom: 0.7em;
  }
  
  .dashbSelect {
    width: 100%;
  
    padding: 0.5em;
  
    border: 1px solid gray;
    border-radius: 5px;
  }
  
  .dashbSelect:focus {
    outline-style: none;
  }
  .firstNameInpt {
    width: 100%;
  
    padding: 0.5em;
  
    border: 1px solid gray;
    border-radius: 5px;
  }
  
  .firstNameInpt:focus {
    outline-style: none;
  }
  
  ::placeholder {
    font-family: "Poppins", sans-serif;
    font-size: clamp(0.8rem, 1vw, 1rem);
  }
  .prefferedBtnsSelected {
    padding: 0.5em;
  
    color:rgb(8, 8, 8);
    font-family: "Poppins", sans-serif;
    font-size: clamp(0.8rem, 1vw, 1rem);
    font-weight: 500;

    background-color: #b8fdff;
      border: 2.5px solid #15585a;
      font-weight: 500;
      
  }
  .dashboardprefferedBtns {
    padding: 0.5em;
  
    background-color: inherit;
    font-family: "Poppins", sans-serif;
    font-size: clamp(0.8rem, 1vw, 1rem);
    font-weight: 500;
  }
  
  .dashboardprefferedBtns:hover {
    background-color: #c2c2c2;
  }
  
  .dashboardprefferedBtns:nth-child(1) {
    border-radius: 5px 0 0 5px;
  }
  
  .dashboardprefferedBtns:nth-child(3) {
    border-radius: 0 5px 5px 0;
  }
  
  .dashBoardContactDetailsSection {
    width: 80%;
  
    margin-top: 5em;
  }
  
  .utilWrap {
    display: flex;
  
    gap: 0.2em;
  }
  
  .phone {
    border-radius: 5px;
  }
  
  .dashBrdSearchAddressBtn {
    width: 100%;
  
    //   background-color: red;
    //   position: relative;
  
    display: grid;
  }
  
  .DshBrdSearch {
    padding: 0.5em;
  
    font-family: "Poppins", sans-serif;
    font-size: clamp(0.8rem, 1vw, 1rem);
    font-weight: 500;
  
    border-radius: 5px;
  
    border: 1px solid gray;
  
    place-self: end;
  
    margin-bottom: 1em;
  
    cursor: pointer;
  }
  
  .DshBrdSearch:hover,
  .DshBrdSearch:focus {
    background-color: #b1b1b1;
  }
  
  .mapDiv {
    width: 100%;
  }
  
  .DashBrdImg {
    border-radius: 5px;
  
    width: 100%;
    margin-top: 1em;
  }
  
  .dashBrdImgCaption {
    font-family: "Poppins", sans-serif;
    font-size: clamp(0.8rem, 1vw, 1rem);
  }
  
  .dshBrdCap {
    display: block;
    margin-top: 1em;
  
    color: gray;
  }
  
  .cautionDshB {
    width: 18px;
  }
  
  .rangeWrapDsh {
    width: 100%;
  
    display: flex;
    flex-direction: row;
  
    justify-content: flex-start;
    align-items: center;
  
    margin-top: 1em;
  }
  
  .dashBrdRange {
    width: 80%;
  
    display: flex;
    flex-direction: row;
  
    justify-content: flex-start;
    align-items: center;
  }
  
  .meters {
    width: 80%;
    height: 2px;
  }
  
  .rangeText {
    font-family: "Poppins", sans-serif;
    font-size: clamp(0.8rem, 1vw, 1rem);
  }
  
  .metersNumber {
    width: 10%;
  
    padding: 0.5em;
    border-radius: 5px;
    border: 1px solid gray;
  
    font-family: "Poppins", sans-serif;
    font-size: clamp(0.8rem, 1vw, 1rem);
  }
  
  .meterChange {
    font-family: "Poppins", sans-serif;
    font-size: clamp(0.8rem, 1vw, 1rem);
  }
  
  .dashBoardAccessDetlsSection {
    width: 80%;
  
    margin-top: 5em;
  }
  
  .textAreaDashBrd {
    width: 100%;
  
    padding: 0.5em;
    border-radius: 5px;
    border: 1px solid gray;
  
    font-family: "Poppins", sans-serif;
    font-size: clamp(0.8rem, 1vw, 1rem);
  }
  
  .dashBoardHighlightsSection {
    width: 80%;
  
    margin-top: 5em;
    margin-bottom: 8em;
  }
//personal *************************************************************

.innerForm {
    width: 100%;
  
    display: flex;
    flex-direction: row;
  
    justify-content: space-between;
    align-items: flex-start;
  }
.IDForm {
    //   background-color: aqua;
    width: 60%;
  }
  
  .personalIdTitleCultureAndReligion {
    width: 100%;
  
    //   background-color: aqua;
  }
  
  .psdtlHead {
    font-family: "poppins", sans-serif;
    font-size: clamp(0.8rem, 1.2vw, 1.2rem);
    font-weight: 500;
    margin-bottom: 1em;

    margin-top: 3em;
  }
  
  .IdGrpQues {
    width: 100%;
  
    margin-top: 2em;
  }
  
  .subTitleHead {
    font-family: "poppins", sans-serif;
    font-size: clamp(0.8rem, 1.05vw, 1.05rem);
    font-weight: 500;
  
    margin-bottom: 0.5em;
  }
  
  .IDQues {
    font-family: "poppins", sans-serif;
    font-size: clamp(0.7rem, 1vw, 1rem);
    font-weight: 400;
  
    //   margin-top: 1em;
  }
  
  .IDInput {
    width: 100%;
  
    padding: 0.6em;
  
    border-radius: 5px;
    border: 2px solid rgba(177, 177, 177, 0.534);
  
    margin-bottom: 1em;
    margin-top: 1em;
  }
  
  .IDInput:focus {
    outline-style: none;
  }
  
  ::placeholder {
    font-family: "poppins", sans-serif;
    font-size: clamp(0.7rem, 1vw, 1rem);
  }
  
  :optional {
    font-family: "poppins", sans-serif;
    font-size: clamp(0.7rem, 1vw, 1rem);
  }
  
  .margBt {
    margin-bottom: 2em;
  }
  
  .personalIdTitleSexuality {
    width: 100%;
  
    margin-top: 4em;
  }
  
  .options {
    width: 100%;
  
    //   background-color: aqua;
    margin-top: 1em;
  }
  
  .option {
    width: 100%;
  
    border: 1px solid rgba(177, 177, 177, 0.534);
    border-radius: 5px;
  
    margin-bottom: 1em;
    padding: 0.5em;
  
    display: flex;
    flex-direction: row;
  
    justify-content: flex-start;
    align-items: center;
  
    gap: 1em;
  }  
  .optLabl {
    font-family: "poppins", sans-serif;
    font-size: clamp(0.7rem, 1vw, 1rem);
    font-weight: 500;
  }

  //edit form
  .innerEditForm {
    width: 100%;
  
    //   padding: 0 1em;
  }
  
  .cancleBtnn {
    text-decoration: none;
   // color: #fff;
  }
  
  .dashBrdFt {
    width: 70vw;
    height: 110px;
    background-color: #ffffff;
  
    display: flex;
    flex-direction: row;
  
    justify-content: center;
    align-self: center;
  
    position: fixed;
    bottom: 2px;
  
    border-top: 1px solid gray;
  }
  
  .nextIconDshBrd {
    width: 17px;
  
    padding: 0.2em;
    background-color: rgb(228, 228, 228);
  }
  
  .nextIconDshBrd:hover {
    background-color: grey;
    transition: 0.5s;
  }
  
  .innerDashbrdft {
    width: 100%;
    display: flex;
    flex-direction: row;
  
    justify-content: space-around;
    align-items: center;
  }
  
  .cancelBtn {
    padding: 0.5em 1em;
  
    font-family: "Poppins", sans-serif;
    font-size: clamp(0.8rem, 1vw, 1rem);
    font-weight: 500;
    border-radius: 5px;
  
    background-color:#fff; ;
    color: #111c4d;
  
    border: none;
  
    cursor: pointer;
  }
  .saveBtn {
    padding: 0.5em 1em;
  
    font-family: "Poppins", sans-serif;
    font-size: clamp(0.8rem, 1vw, 1rem);
    font-weight: 500;
    border-radius: 5px;
  
    background-color: #111c4d;
    color: #fff;
  
    border: none;
  
    cursor: pointer;
  }
  
  .cancelBtn:hover {
    background-color: #a5a8b3;
    transition: 0.5s;
  }
  .saveBtn:hover {
    background-color: #0b0f1d;
    transition: 0.5s;
  }
  
  .nextFromSection {
    display: flex;
    flex-direction: row;
  
    justify-content: center;
    align-items: center;
  
    gap: 3em;
  }
  
  .nextTabDshBrd {
    font-family: "Poppins", sans-serif;
    font-size: clamp(0.8rem, 1vw, 1rem);
  }
  .checkboxText {
    font-family: "poppins", sans-serif;
    font-size: clamp(0.7rem, 1.05vw, 1.05rem);
    // font-weight: 450;
  }
  .lengSelt {
    width: 20%;
  }
  .hldTogether {
    width: 100%;
  
    display: flex;
    flex-direction: row;
  
    justify-content: flex-start;
    align-self: center;
  
    gap: 0.5em;
  }
  .padBtn {
    padding: 0.5em 0.8em;
    border: 1px solid #a9a6a6;
  
    font-family: "poppins", sans-serif;
    font-size: clamp(0.7rem, 1.05vw, 1.05rem);
    font-weight: 450;
  
    margin-top: 0.5em;
  }
  
  .padBtn:hover,
  .padBtn:focus {
    background-color: #1096b8;
  }
  
  .magrBtm {
    margin-bottom: 4em;
  }
  .inbetweenHead {
    margin-bottom: 1em;
  
    margin-top: 3em;
  }
  .grpButtons{
    margin-bottom: 3em;
  }