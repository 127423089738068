section {
  width: 100%;
  margin: 0 auto;
}

.innerThirdSection {
  //   background-color: gray;
  width: 100%;

  padding: 2em;

  margin: 1em 0em;
  margin-bottom: 2em;
}

.partnersText {
  font-family: "poppins", sans-serif;
  font-size: clamp(1rem, 1.3vw, 1.3rem);
  font-weight: 700;

  text-transform: uppercase;
  text-align: center;

  letter-spacing: 0.2rem;
}

.partnerParagraph {
  font-family: "poppins", sans-serif;
  font-size: clamp(0.65rem, 1.1vw, 1.1rem);
  font-weight: 600;

  text-align: center;

  margin-top: 0.5em;
}

.partnerLogoWrap {
  width: 100%;

  //   background-color: rgb(182, 182, 182);

  /*   display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  justify-content: space-evenly;
  align-items: center;

  gap: 0.6em;

  margin: 0 auto;

  margin-top: 1em; */

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: auto;

  gap: 0.5em;

  // background-color: rgb(182, 182, 182);

  margin-top: 1em;
}

.logoWrap {
  // width: 103px;
  height: 58px;

  border: 1px solid gray;
  border-radius: 10px;

  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
}

.thirdSecLogo {
  width: 60px;
}

@media (min-width: 630px) {
  .logoWrap {
    // width: 103px;
    height: 11vh;

    border: 1px solid gray;
    border-radius: 10px;

    display: flex;
    flex-direction: row;

    justify-content: center;
    align-items: center;
  }

  .thirdSecLogo {
    width: 65px;
  }
}

@media (min-width: 828px) {
  .partnerLogoWrap {
    width: 60%;

    //   background-color: rgb(182, 182, 182);

    /*   display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  
    justify-content: space-evenly;
    align-items: center;
  
    gap: 0.6em;
  
    margin: 0 auto;
  
    margin-top: 1em; */

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: auto;

    gap: 0.5em;

    // background-color: rgb(182, 182, 182);
    margin: 0 auto;

    margin-top: 1em;

    place-content: center;
  }

  .logoWrap {
    width: 120px;
    height: 58px;

    border: 1px solid gray;
    border-radius: 10px;

    display: flex;
    flex-direction: row;

    justify-content: center;
    align-items: center;
  }

  .thirdSecLogo {
    width: 60px;
  }
}

@media (min-width: 1000px) {
  .innerThirdSection {
    //   background-color: gray;
    width: 100%;

    padding: 2em;

    margin: 3em 0em;
    margin-bottom: 2em;
  }

  .partnersText {
    font-family: "poppins", sans-serif;
    font-size: clamp(1rem, 1.1vw, 1.1rem);
    font-weight: 600;

    text-transform: uppercase;
    text-align: center;

    letter-spacing: 0.2rem;
  }

  .partnerParagraph {
    font-family: "poppins", sans-serif;
    font-size: clamp(1.2rem, 1.5vw, 1.5rem);
    font-weight: 600;

    text-align: center;

    margin-top: 0.5em;
  }

  .partnerLogoWrap {
    width: 60%;

    //   background-color: rgb(182, 182, 182);

    /*   display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  
    justify-content: space-evenly;
    align-items: center;
  
    gap: 0.6em;
  
    margin: 0 auto;
  
    margin-top: 1em; */

    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: auto;

    gap: 1em;

    // background-color: rgb(182, 182, 182);
    margin: 0 auto;

    margin-top: 2em;

    place-content: center;
  }

  .logoWrap {
    width: 120px;
    height: 58px;

    border: 1px solid gray;
    border-radius: 10px;

    display: flex;
    flex-direction: row;

    justify-content: center;
    align-items: center;
  }

  .thirdSecLogo {
    width: 60px;
  }
}
