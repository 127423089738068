section {
  width: 100%;
  margin: 0 auto;
}

.innerSecondSection {
  width: 100%;

  //   background-color: grey;

  overflow-x: hidden;

  margin-top: 1em;
  margin-bottom: 1em;

  padding: 1em;
}

.secondHeadText {
  //   width: 90%;

  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(1.55rem, 2vw, 2rem);
  font-weight: 500;

  // padding: 0em 1em;

  //   background-color: red;
}

.dBlk {
  display: block;
}

.secondSectionSlides {
  width: 100%;
  //   height: 380px;
  //   background-color: rgb(177, 177, 177);

  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  gap: 1.5em;

  padding: 0em 1.5em;

  overflow: auto;
  //   scroll-snap-type: proximity;
  scroll-behavior: smooth;
}

.secondSectionSlides::-webkit-scrollbar {
  width: 0;
}

.slideWrap {
  width: 70%;
  min-width: 260px;
  height: 380px;
  // background-color: rgb(145, 15, 15);

  margin: 1.5em 0em;
  border-radius: 23px;

  //   background-image: url(../assets/sectionSection/ashkan-forouzani-DPEPYPBZpB8-unsplash.jpg);

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.innerSlideWrap {
  width: 100%;
  height: 380px;

  border-radius: 23px;

  background: linear-gradient(180deg, rgba(2, 2, 2, 0) 40%, #000000 110%);

  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-end;

  & .slideText {
    font-family: "Space Grotesk", sans-serif;
    font-size: clamp(1.3rem, 1.4vw, 1.4rem);
    font-weight: 500;

    padding: 1em 1em;

    color: #fff;
  }

  & .manage {
    display: block;
  }
}

.SecondSecNavigate {
  width: 100%;

  padding: 0em;

  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 1em;

  // background-color: red;

  margin-top: -1em;
}

.navig {
  width: 20px;
}

.navig:hover {
  width: 28px;
  background-color: rgb(190, 190, 190);
  transition: 0.3s ease-in;

  padding: 0.2em;
  border-radius: 10px;
}

@media (min-width: 680px) {
  .secondHeadText {
    width: 70%;

    font-family: "Space Grotesk", sans-serif;
    font-size: clamp(1.55rem, 2vw, 2rem);
    font-weight: 500;

    // padding: 0em 1em;

    margin: 0 auto;
    //   background-color: red;
    margin-bottom: 1em;

    text-align: center;
  }

  .dBlk {
    display: inline;
  }

  .slideWrap {
    width: 70%;
    min-width: 260px;
    height: 380px;
    // background-color: rgb(145, 15, 15);

    margin: 1.5em 0em;
    border-radius: 23px;

    //   background-image: url(../assets/sectionSection/ashkan-forouzani-DPEPYPBZpB8-unsplash.jpg);

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .slideWrap:hover {
    transition: 0.5s;

    animation: animate 0.5s linear 0.2s 1 forwards;
  }

  @keyframes animate {
    from {
      background-size: 80vh;
    }

    to {
      background-size: 100vh;
    }
  }
}

@media (min-width: 828px) {
  .innerSecondSection {
    width: 100%;

    //   background-color: grey;

    overflow-x: hidden;

    margin-top: 3em;
    margin-bottom: 2em;

    padding: 1em;
  }

  .secondHeadText {
    width: 50%;

    font-family: "Space Grotesk", sans-serif;
    font-size: clamp(1.55rem, 2vw, 2rem);
    font-weight: 500;

    // padding: 0em 1em;

    margin: 0 auto;
    //   background-color: red;
    margin-bottom: 1em;

    text-align: center;
  }
}

@media (min-width: 1100px) {
  .innerSecondSection {
    width: 100%;

    //   background-color: grey;

    overflow-x: hidden;

    margin-top: 2em;
    margin-bottom: 2em;

    padding: 1em;

    display: flex;
    flex-direction: row;

    justify-content: space-between;
    align-items: center;

    position: relative;
  }

  .secondHeadText {
    width: 50%;
    min-width: 300px;
    // background-color: red;

    font-family: "Space Grotesk", sans-serif;
    font-size: clamp(1.4rem, 2vw, 2rem);
    font-weight: 500;

    padding: 0em 1em;

    margin: 0 auto;
    // background-color: red;
    margin-bottom: 1em;

    text-align: left;
  }

  .SecondSecNavigate {
    width: 100%;

    padding: 0em;

    display: flex;
    flex-direction: row;

    justify-content: center;
    align-items: center;

    gap: 1em;

    // background-color: red;

    margin-top: -1em;

    position: absolute;
    bottom: 0em;
  }

  .navig {
    width: 20px;
  }

  .navig:hover {
    width: 28px;
    background-color: rgb(190, 190, 190);
    transition: 0.3s ease-in;

    padding: 0.2em;
    border-radius: 10px;
  }

  .secondSectionSlides {
    width: 100%;
    //   height: 380px;
    //   background-color: rgb(177, 177, 177);

    display: flex;
    flex-direction: row;

    justify-content: flex-start;
    align-items: center;

    gap: 1.5em;

    padding: 0em 1.5em;

    overflow: auto;
    //   scroll-snap-type: proximity;
    scroll-behavior: smooth;
  }

  .secondSectionSlides::-webkit-scrollbar {
    width: 0;
  }

  .slideWrap {
    // width: 70%;
    min-width: 100px;
    height: 380px;
    // background-color: rgb(145, 15, 15);

    margin: 1.5em 0em;
    border-radius: 15px;

    //   background-image: url(../assets/sectionSection/ashkan-forouzani-DPEPYPBZpB8-unsplash.jpg);

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    transition: all 0.5s ease-in;
  }

  .innerSlideWrap {
    width: 100%;
    height: 380px;

    border-radius: 15px;

    background: linear-gradient(180deg, rgba(2, 2, 2, 0) 40%, #000000 110%);

    display: flex;
    flex-direction: row;

    justify-content: flex-start;
    align-items: flex-end;

    & .slideText {
      font-family: "Space Grotesk", sans-serif;
      font-size: clamp(1.3rem, 1.4vw, 1.4rem);
      font-weight: 500;

      padding: 1em 1em;

      color: #fff;
    }

    & .manage {
      display: block;
    }
  }

  .slideWrap:hover {
    transition: 0.5s;

    animation: none;
  }
}
