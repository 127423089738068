section {
  width: 100%;
  margin: 0 auto;
}

.innerSixSection {
  width: 100%;

  //   background-color: grey;

  margin: 0 auto;

  padding: 2em;
  padding-top: 1em;
}

.excellentWrap {
  width: 100%;

  margin: 0 auto;

  // background-color: red;
}

.star {
  width: 100%;

  margin: 0 auto;

  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
}

.starRate {
  width: 16px;

  margin-right: 0.3em;
}

.excellentHeadText {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(1.6rem, 2vw, 2rem);
  font-weight: 700;

  text-transform: uppercase;
  text-align: center;

  margin-top: 0.2em;
}

.rateText {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.8rem, 1.4vw, 1.4rem);
  font-weight: 500;

  text-align: center;

  margin-top: 0.4em;
}

.listWrap {
  width: 100%;
  //   height: 100vh;
  margin: 0 auto;

  //   background-color: yellowgreen;

  margin-top: 1em;

  overflow: hidden;

  margin-bottom: 1.5em;

  transition: all 0.5s;
}

.list {
  width: 100%;

  background-color: rgb(172, 172, 172);
  padding: 1em;

  border-radius: 15px;

  position: relative;

  margin-bottom: 0.4em;
}

.rateName {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.7rem, 1.1vw, 1.1rem);
  //   font-weight: 500;
}

.rateParagraph {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.7rem, 1.1vw, 1.1rem);
  font-weight: 500;
}

.arrowDwn {
  width: 15px;

  position: absolute;
  top: 1.3em;
  right: 1.3em;
}

.SeeMore {
  width: 100%;
  height: 12vh;
  min-height: 70px;

  background-color: #1b2034;
  padding: 1em;

  border-radius: 15px;

  margin-bottom: 0.8em;

  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  gap: 1em;
}

.seeMoreText {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.7rem, 1.1vw, 1.1rem);
  font-weight: 500;

  color: #fff;
}

.arrowRightLong {
  width: 20px;
}

.arrowRightLong:hover,
.arrowRightLong:focus {
  width: 30px;
}

@media (min-width: 700px) {
  .excellentWrap {
    width: 45vw;

    margin: 0;

    // background-color: red;

    border: 1px solid gray;
  }

  .star {
    width: 100%;

    justify-content: flex-start;
  }

  .starRate {
    width: 16px;

    margin-right: 0.3em;
  }

  .excellentHeadText {
    font-family: "Space Grotesk", sans-serif;
    font-size: clamp(1.6rem, 2vw, 2rem);
    font-weight: 700;

    text-transform: uppercase;
    text-align: left;

    margin-top: 0.2em;
  }

  .rateText {
    font-family: "Space Grotesk", sans-serif;
    font-size: clamp(0.8rem, 1.4vw, 1.4rem);
    font-weight: 500;

    text-align: left;

    margin-top: 0.4em;
  }

  .listWrap {
    width: 100%;
    height: 0vh;
    margin: 0 auto;

    //   background-color: yellowgreen;

    display: grid;
    grid-template-columns: repeat(3, 1fr);

    gap: 1em;

    margin-top: 1em;

    overflow: hidden;

    margin-bottom: 1.5em;

    transition: all 0.5s;
  }
}

@media (min-width: 970px) {
  .excellentWrap {
    width: 45vw;

    margin: 0 auto;

    // background-color: red;

    border: 1px solid gray;

    margin-top: 1em;
    margin-bottom: 2em;
  }

  .listWrap {
    width: 800px;
    height: 0vh;
    margin: 0 auto;

    // background-color: yellowgreen;

    display: grid;
    grid-template-columns: repeat(3, 1fr);

    gap: 1em;

    // justify-content: center;
    // align-content: flex-start;

    justify-items: center;

    margin-top: 1em;

    overflow: hidden;

    margin-bottom: 1.5em;

    transition: all 0.5s;
  }

  .list {
    width: 250px;

    background-color: rgb(172, 172, 172);
    padding: 1em;

    border-radius: 15px;

    position: relative;

    margin-bottom: 0.4em;
  }

  .SeeMore {
    width: 30%;
    height: 12vh;
    min-height: 70px;

    background-color: #1b2034;
    padding: 1em;

    border-radius: 15px;

    margin-bottom: 0.8em;

    display: flex;
    flex-direction: row;

    justify-content: flex-start;
    align-items: center;

    gap: 1em;

    margin: 0 auto;

    margin-top: 2em;
  }
}
