

.stateLabel {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.8rem, 1vw, 1rem);
  font-weight: 500;
}

.cmnAtr {
  //   width: 40%;
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  //   background-color: red;

  gap: 0.3em;
  margin-bottom: 0.2em;
}

.searchWrap {
  //   width: 45%;
  position: relative;
}

.search {
  width: 20%;
  min-width: 280px;

  border-radius: 10px;
  border: none;

  padding: 0.3em 2em;
  padding-right: 0.5em;

  margin-top: 0.5em;

  border: 1px solid #000;

  //   z-index: -1;
}

.search:focus {
  outline-style: none;
}

.searcIcon {
  width: 12px;
  aspect-ratio: 1/1;

  position: absolute;
  top: 1em;
  left: 0.6em;
}

.selectOpt {
  padding: 0.3em 1em;

  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.8rem, 1vw, 1rem);
  font-weight: 500;

  text-transform: uppercase;

  border-radius: 10px;

  margin-top: 0.5em;
}

.selectOpt:focus {
  outline-style: none;
}

.opts {
  border-radius: 10px;
}

@media (min-width: 520px) {
  .form {
    display: flex;
    flex-direction: row;

    justify-content: flex-start;
    align-items: center;

    gap: 1em;
  }

  .serarchAndCategory {
    display: flex;
    flex-direction: row;

    justify-content: flex-start;
    align-items: center;

    // background-color: red;

    gap: 1em;
    flex:1;
  }
}

@media (min-width: 760px) {
  .ClientBoardSecondHeader {
    width: 100%;

    //   background-color: grey;

    margin-top: 5em;

    //   position: relative;
    //   z-index: -1;
  }
}

@media (min-width: 850px) {
  .ClientBoardSecondHeader {
    width: 100%;

    //   background-color: grey;

    margin-top: 6em;

    //   position: relative;
    //   z-index: -1;
  }

  .innerClientBoardSecondHeader {
    width: 100%;

    padding: 1em 2em;
    margin: 0 auto;

    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;
  }

  .form {
    width: 30%;
    min-width: 400px;
    display: flex;
    flex-direction: row;

    justify-content: flex-start;
    align-items: center;

    gap: 1.2em;

    // background-color: green;
  }

  .statesAndSearch {
    width: 100%;

    // background-color: red;

    display: flex;
    flex-direction: row;

    justify-self: start;
    align-items: center;

    gap: 1em;
  }

  .serarchAndCategory {
    display: flex;
    flex-direction: row;

    justify-content: flex-start;
    align-items: center;

    // background-color: red;

    gap: 1em;
  }

  .searchWrap {
    width: 50%;
    position: relative;
  }

  .search {
    width: 100%;
    min-width: 120px;

    border-radius: 10px;
    border: none;

    padding: 0.3em 2em;
    padding-right: 0.5em;

    margin-top: 0em;

    border: 1px solid grey;

    //   z-index: -1;
  }

  .searcIcon {
    width: 12px;
    aspect-ratio: 1/1;

    position: absolute;
    top: 0.6em;
    left: 0.6em;
  }

  .selectOpt {
    display: flex;
    padding: 0.3em 0.5em;

    font-family: "Space Grotesk", sans-serif;
    font-size: clamp(0.8rem, 1vw, 1rem);
    font-weight: 500;

    text-transform: uppercase;

    border-radius: 10px;

    margin-top: 0em;

    border: none;

    background-color: blue;
    color: white;
  }
}
