.WeCareMessageBody {
  width: 100%;

  margin: 0 auto;

  margin-top: 4.5em;
}

.innerWeCareMessageBody {
  width: 100%;

  padding: 0em 2em;
}

.soundsLike {
  width: 100%;
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(1rem, 1.1vw, 1.1rem);
  font-weight: 600;

  color: #001a5c;
}

.weCareForm {
  width: 100%;
}

.weCareMessageLabel {
  position: absolute;
  left: -999999px;
}

.weCareMessageInput {
  width: 100%;
  padding: 0.5em;

  margin-bottom: 0.5em;

  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.8rem, 1.1vw, 1.1rem);
}

.weCareMessageInput:focus {
  outline-style: none;
}

.weCareMessageInput::placeholder {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.9rem, 1vw, 1rem);
}

.weCareMessageDesc {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.9rem, 1vw, 1rem);
  font-weight: 600;

  margin-top: 1em;
}

.weCareMessageBtn {
  width: 60%;
  //   min-width: 350px;

  background-color: #01247e;
  color: #fff;

  padding: 1em;

  font-family: "poppins", sans-serif;
  font-size: clamp(1.2rem, 1.3vw, 1.3rem);
  font-weight: 600;

  text-transform: uppercase;

  border: none;
  border-radius: 6px;

  margin-top: 1em;
}

.weCareMessageBtn:hover {
  background-color: #001a5c;
}

@media (min-width: 820px) {
  .WeCareMessageBody {
    width: 100%;

    margin: 0 auto;

    margin-top: 8em;
  }

  .soundsLike {
    width: 55%;
    font-family: "Space Grotesk", sans-serif;
    font-size: clamp(1rem, 1.1vw, 1.1rem);
    font-weight: 600;

    color: #001a5c;
  }
  .weCareForm {
    width: 100%;

    display: grid;
    grid-template-columns: repeat(2, minmax(360px, 1fr));

    grid-column-gap: 2.5em;
    grid-row-gap: 0.7em;

    place-content: center;
  }

  .weCareMessageInput {
    width: 100%;
    padding: 0.8em;

    margin-bottom: 0em;

    font-family: "Space Grotesk", sans-serif;
    font-size: clamp(0.8rem, 1.1vw, 1.1rem);

    border-radius: 10px;

    border: 1px solid grey;
  }

  .weCareMessageDesc {
    grid-column-start: 1;
    grid-column-end: -1;
  }

  .weCareMessageBtn {
    width: 50%;
    min-width: 350px;

    background-color: #01247e;
    color: #fff;

    padding: 1em;

    font-family: "poppins", sans-serif;
    font-size: clamp(1.2rem, 1.3vw, 1.3rem);
    font-weight: 600;

    text-transform: uppercase;

    border: none;
    border-radius: 6px;

    margin-top: 1em;
  }
}
