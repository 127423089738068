.sideBar {
  width: 290px;
  min-height: calc(100vh - 100px);

  background-color: #f7f7f7;
  box-shadow: 1px 23px 29px rgba(0, 0, 0, 0.18);

  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: flex-start;

  //   position: relative;
}

.innerDashboardSideBar {
  width: 100%;
  height: 100%;

  //   background-color: green;

  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  //   position: relative;

  margin-top: 6vh;

  padding: 0em 2em;
}

.linkImg {
  width: 12px;
}

.sideBarLinkWrap {
  width: 100%;
  list-style: none;

  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  gap: 0.4em;
}

.sideBarLink {
  width: 100%;

  //   padding: 0.5em;
  //   background-color: yellow;
}

.sideBarLinkk {
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  gap: 1em;

  text-decoration: none;
  font-family: "poppins", sans-serif;
  font-size: clamp(0.75rem, 0.9vw, 0.9rem);

  color: #000;

  padding: 0.7em 1em;
}

.sideBarLinkk:hover,
.sideBarLinkk:focus {
  background-color: #dddddd;

  transition: 0.3s;
}

.sideBarAccPixAndName {
  width: 100%;

  //   background-color: yellow;

  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  gap: 0.5em;

  padding: 0em 0em;

  margin-top: 2em;

  //   position: absolute;
  //   bottom: 100em;
}

.accPix {
  width: 45px;
  aspect-ratio: 1/1;

  border-radius: 50px;

  object-fit: cover;
}

.sideBarAccName {
  font-family: "poppins", sans-serif;
  font-size: clamp(0.6rem, 0.8vw, 0.8rem);
  font-weight: 600;
}

@media (max-width: 1240px) {
  .sideBar {
    width: 260px;

    min-height: calc(100vh - 100px);

    background-color: #f7f7f7;
    box-shadow: 1px 23px 29px rgba(0, 0, 0, 0.18);

    display: flex;
    flex-direction: row;

    justify-content: center;
    align-items: flex-start;

    //   position: relative;
  }
}

@media (max-width: 1004px) {
  .sideBar {
    min-width: 240px;
  }
}
