.ClientBoardBody {
  width: 100%;
  margin: 0 auto;

  margin-top: 2em;
  margin-bottom: 2em;
}

.innerClientBoardBody {
  width: 100%;

  /* display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  gap: 1em; */

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));

  grid-gap: 15px;

  place-content: center;
}

.cellFirst {
  width: 100%;
  height: 20vh;
  background-color: rgb(223, 223, 223);

  display: flex;
  flex-direction: column;

  justify-content: space-between;
  align-items: flex-start;

  //   padding: 1.2em;
}

.cellFirst:hover {
  background-color: #afafaf;
  box-shadow: 0px 10px 20px 1px rgba(0, 0, 0, 0.25);
  border-radius: 2px;
}

.accProf {
  width: 100%;

  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  gap: 1em;

  //   background-color: #fff;

  padding: 1.5em 1.2em;

  border-bottom: 1px solid white;
}

.accProfPix {
  width: 50px;
  aspect-ratio: 1/1;

  background-color: #1b2034;

  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  border-radius: 50px;
}

.accProNam {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.8rem, 1vw, 1rem);
  font-weight: 800;

  color: #fff;
}

.clientBdName {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.7rem, 1vw, 1rem);
  font-weight: 800;
}

.clientBdState {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.7rem, 1vw, 1rem);

  background-color: #fff;

  border-radius: 2px;

  padding: 0.1em 0.2em;
  padding-right: 1em;
}

.riskLevelAndGrp {
  width: 100%;

  padding: 1.5em 1.2em;
  margin-top: -1em;
  display: flex;
  align-items: center;
  align-content: center;

  //   background-color: #fff;
}

.riskLvlText {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.8rem, 1vw, 1rem);
  font-weight: 500;
  display: flex;
  flex: 1;
}

.stateLvl {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.9rem, 1vw, 1rem);
  font-weight: 500;

  background-color: #ffc143;

  border-radius: 50px;

  padding: 0.1em 0.4em;

  margin-left: 0.2em;
  
}

.grup {
 flex:1;

  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  align-items: center;

  /* gap: 0.4em;

  margin-top: 0.5em; */
}

.clientGrp {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.9rem, 1vw, 1rem);
  font-weight: 500;
}

.clientGrpName {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.9rem, 1vw, 1rem);
  font-weight: 500;

  background-color: #ffc143;

  border-radius: 50px;

  padding: 0.1em 2em;
}

@media (min-width: 1024px) {
  .innerClientBoardBody {
    width: 100%;

    /* display: flex;
        flex-direction: column;
      
        justify-content: center;
        align-items: center;
      
        gap: 1em; */

    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));

    grid-gap: 20px;

    place-content: center;
  }
}
