*, :after, :before {
   box-sizing: border-box; 
}
.loginWrap {
  width: 100%;
  height: 100vh;
  //   height: 100dvw;

  margin: 0 auto;

  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  //   position: relative;
}

.login {
  width: 50%;

  margin: 0 auto;
  align-items: center;
  align-content: center;
  padding: 2em 4em;
}

.weCareFormLogo {
  width: 10vw;
  min-width: 150px;
}

.logInHeadText {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(1.4rem, 1.9vw, 1.7rem);
  margin-top: 0.5em;
}

.welcomeBack {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.9rem, 1vw, 1rem);
}

.weCareForm {
  width: 100%;
align-items: center !important;
align-content: center !important;
  //   background-color: rgb(219, 219, 219);
  margin-top: 2em;
}

.weCareLabel {
  position: absolute;
  left: -999999px;
}

.weCareInput {
  width: 75%;

  display: block;
  margin-bottom: 0.5em;

  padding: 1em;
  padding-right: 4em;

  border-radius: 10px;
  border: 2px solid gray;

  background: none;

  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.9rem, 1vw, 1rem);
}

.weCareInput:focus {
  outline-style: none;
}

.passwordInput {
  width: 100%;
}

.pw {
  position: relative;
  width: 75%;

  //   background-color: red;
}

.weCareInput::placeholder {
  color: gray;

  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.9rem, 1vw, 1rem);
}

.reveal {
  width: 20px;

  position: absolute;
  top: 1.2em;
  right: 30px;
}

.rememberAndForgotPword {
  width: 75%;
  //   height: 35px;
  //   background-color: red;

  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  padding: 0em 0.3em;
}

.holdTight {
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
}

.rememberMe {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.75rem, 1.2vw, 1.2rem);

  padding: 0em 0.5em;

  margin-block: 0;
  margin-inline: 0;
}

.forgotPW {
  margin-top: 10px;
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.7rem, 1.1vw, 1.1rem);
}

.forgotPW a {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.6rem, 1.1vw, 1.1rem);
  text-decoration: none;
  color: blue;
}

.btnWrapp {
  width: 75%;

  //   background-color: gray;

  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
}

.loginSubmit {
  width: 100%;

  padding: 1.2em;

  background-color: #0f2255;
  color: #fff;

  border: none;
  border-radius: 16px;

  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.8rem, 1.2vw, 1.2rem);

  margin-top: 1em;
}

.loginSubmit:hover,
.loginSubmit:focus {
  background-color: #314b92;
  transition: 0.5s;
}

.registerANew {
  width: 75%;

  margin-top: 1em;
}

.dontHaveAcc {
  text-align: center;

  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.6rem, 1.2vw, 1.2rem);
}

.dontHaveAcc a {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(0.6rem, 1.2vw, 1.2rem);
  font-weight: 600;
  text-decoration: none;

  color: blue;
}

.logInSidePicture {
  width: 50%;
  height: 100%;

  background-image: url(../assets/pexels-rodnae-productions-6647037.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  position: relative;
}

.innerLoginSide {
  width: 100%;
  height: 100%;

  background: rgba(59, 67, 88, 0.7);
}

.weCareAllTimes {
  width: 30%;

  background-color: #e2e2e2;
  border-radius: 10px;

  position: absolute;
  left: 3em;
  bottom: 2em;
}

.weCareAl {
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(1.3rem, 2vw, 2rem);
  font-weight: 600;

  color: #1a2f6b;

  padding: 1.5em 0em;
  padding-left: 1em;
}

.logBrk {
  display: block;
}

.innerForm {
 
align-items: center;
align-content: center;
  margin: 0 auto;
}

@media (max-width: 980px) {
  .login {
    width: 100%;

    margin: 0 auto;

    padding: 2em 4em;

    // background-color: rgb(207, 207, 207);
  }

  .loginTexts {
    width: 75%;

    // background-color: yellow;

    margin: 0 auto;
  }

  .weCareForm {
    width: 100%;

    // background-color: rgb(219, 219, 219);
    margin-top: 2em;
  }

  .innerForm {
    width: 75%;
align-items: center;
align-content: center;
    margin: 0 auto;
  }

  .weCareInput {
    width: 100%;

    display: block;
    margin-bottom: 0.5em;

    padding: 1em;
    padding-right: 4em;

    border-radius: 10px;
    border: 2px solid gray;

    background: none;

    font-family: "Space Grotesk", sans-serif;
    font-size: clamp(0.9rem, 1vw, 1rem);
  }

  .pw {
    position: relative;
    width: 100%;

    //   background-color: red;
  }

  .rememberAndForgotPword {
    width: 100%;
    //   height: 35px;
    //   background-color: red;

    display: flex;
    flex-direction: row;

    justify-content: space-between;
    align-items: center;

    padding: 0em 0.3em;
  }

  .rememberMe {
    font-family: "Space Grotesk", sans-serif;
    font-size: clamp(0.9rem, 1.2vw, 1.2rem);

    padding: 0em 0.5em;

    margin-block: 0;
    margin-inline: 0;
  }

  .forgotPW {
    font-family: "Space Grotesk", sans-serif;
    font-size: clamp(0.9rem, 1.1vw, 1.1rem);
  }

  .forgotPW a {
    font-family: "Space Grotesk", sans-serif;
    font-size: clamp(0.85rem, 1.1vw, 1.1rem);
    text-decoration: none;
    color: blue;
  }

  .btnWrapp {
    width: 100%;

    //   background-color: gray;

    display: flex;
    flex-direction: row;

    justify-content: center;
    align-items: center;
  }

  .loginSubmit {
    width: 100%;

    padding: 1.2em;

    background-color: #0f2255;
    color: #fff;

    border: none;
    border-radius: 16px;

    font-family: "Space Grotesk", sans-serif;
    font-size: clamp(1rem, 1.2vw, 1.2rem);

    margin-top: 1em;
  }

  .loginSubmit:hover,
  .loginSubmit:focus {
    background-color: #314b92;
    transition: 0.5s;
  }

  .registerANew {
    width: 100%;

    margin-top: 1em;
  }

  .dontHaveAcc {
    text-align: center;

    font-family: "Space Grotesk", sans-serif;
    font-size: clamp(0.8rem, 1.2vw, 1.2rem);
  }

  .dontHaveAcc a {
    font-family: "Space Grotesk", sans-serif;
    font-size: clamp(0.8rem, 1.2vw, 1.2rem);
    font-weight: 600;
    text-decoration: none;

    color: blue;
  }

  .logInSidePicture {
    display: none;
  }
}

@media (max-width: 580px) {
  .loginTexts {
    width: 100%;

    // background-color: yellow;

    margin: 0 auto;
  }

  .innerForm {
    width: 100%;

    margin: 0 auto;
  }

  .weCareFormLogo {
    // width: 19vw;
    min-width: 100px;
  }

  .logInHeadText {
    font-family: "Space Grotesk", sans-serif;
    font-size: clamp(1rem, 1.3vw, 1.3rem);
    margin-top: 1em;
  }

  .welcomeBack {
    font-family: "Space Grotesk", sans-serif;
    font-size: clamp(0.65rem, 1vw, 1rem);
  }

  .weCareInput {
    padding: 0.6em 1em;
    // padding-right: 3em;
    // padding-left: 1em;

    font-family: "Space Grotesk", sans-serif;
    font-size: clamp(0.8rem, 1vw, 1rem);

    border-radius: 7px;
  }

  .reveal {
    width: 17px;

    position: absolute;
    top: 0.7em;
    right: 1em;
  }

  .weCareInput::placeholder {
    color: gray;

    font-family: "Space Grotesk", sans-serif;
    font-size: clamp(0.85rem, 1vw, 1rem);
  }
}

@media (max-width: 420px) {
  .login {
    width: 100%;

    margin: 0 auto;

    padding: 1em 2em;
  }

  .weCareInput::placeholder {
    color: gray;

    font-family: "Space Grotesk", sans-serif;
    font-size: clamp(0.7rem, 1vw, 1rem);
  }

  .rememberMe {
    font-family: "Space Grotesk", sans-serif;
    font-size: clamp(0.7rem, 1.2vw, 1.2rem);

    padding: 0em 0.5em;

    margin-block: 0;
    margin-inline: 0;
  }

  .forgotPW {
    font-family: "Space Grotesk", sans-serif;
    font-size: clamp(0.65rem, 1.1vw, 1.1rem);
  }

  .forgotPW a {
    font-family: "Space Grotesk", sans-serif;
    font-size: clamp(0.65rem, 1.1vw, 1.1rem);
    text-decoration: none;
    color: blue;
  }

  .loginSubmit {
    width: 100%;

    padding: 1.2em;

    background-color: #0f2255;
    color: #fff;

    border: none;
    border-radius: 12px;

    font-family: "Space Grotesk", sans-serif;
    font-size: clamp(0.7rem, 1.2vw, 1.2rem);

    margin-top: 1em;
  }

  .dontHaveAcc {
    text-align: center;

    font-family: "Space Grotesk", sans-serif;
    font-size: clamp(0.65rem, 1.2vw, 1.2rem);
  }

  .dontHaveAcc a {
    font-family: "Space Grotesk", sans-serif;
    font-size: clamp(0.65rem, 1.2vw, 1.2rem);
    font-weight: 600;
    text-decoration: none;

    color: blue;
  }
}
